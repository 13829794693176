import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import ms from "components/modals/ModalStyled";
import ProjectConfirmed from "../projects/stages/project_confirmed";
import EstimateRequest from "../projects/stages/estimate_request";
import EstimateSent from "../projects/stages/estimate_sent";
import LeadStage from "../projects/stages/lead_stage";
import InvoiceReady from "../projects/stages/invoice_ready";
import ProjectComment from "../projects/project_comment";
import ExitIcon from "../icons/exit_icon";
import DropperContainer from "../common/dropper_container";
import ProjectDeets from "../projects/project_deets";
import ModalTag from "../common/modal_tag";
import ProjectContacts from "../projects/project_contacts";
import TeamAdd from "../common/team_add";
import InvoiceDetails from "../invoices/InvoiceDetails";
import EditIcon from "../icons/edit_icon";
import ProspectStage from "../projects/stages/prospect_stage";
import ProspectTimeline from "../common/prospect_timeline";
import styled from "styled-components";

const STAGES = [
  "leading",
  "est_requesting",
  "est_sending",
  "project_confirming",
  "testing",
  "completing",
  "readying",
  "invoicing",
  "prospect",
];

const [
  LEADING,
  EST_REQUESTING,
  EST_SENDING,
  PROJECT_CONFIRMING,
  TESTING,
  COMPLETING,
  READYING,
  INVOICING,
  PROSPECT,
] = STAGES;

class ProjectStageModal extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      clientName: data.project.client_name,
      currentUser: data.currentUser,
      project: data.project,
      contacts: data.project.contacts,
      comments: data.project.comments,
      prospectEvents: data.project.prospect_events,
      commentsSearch: "",
      searchComments: [],
      clientEdit: false,
      commentInput: "",
      statusUpdateText: "",
      monthly_invoice: data.project.monthly_invoice,
    };
    this.submitData = this.submitData.bind(this);
    this.submitFileData = this.submitFileData.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.commentSubmit = this.commentSubmit.bind(this);
    this.commentHandler = this.commentHandler.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.updateSearchHandler = this.updateSearchHandler.bind(this);
    this.updateProjectStatus = this.updateProjectStatus.bind(this);
    this.contactHandler = this.contactHandler.bind(this);
    this.editContactHandler = this.editContactHandler.bind(this);
    this.dropCard = this.dropCard.bind(this);
    this.addContactHandler = this.addContactHandler.bind(this);
    this.deleteContactHandler = this.deleteContactHandler.bind(this);
    this.toggleClientEdit = this.toggleClientEdit.bind(this);
    this.focusMount = this.focusMount.bind(this);
    this.updateClientName = this.updateClientName.bind(this);
    this.saveClientName = this.saveClientName.bind(this);
    this.copyProject = this.copyProject.bind(this);
  }

  componentDidMount() {
    this.focusMount();
  }

  moveToTaskboard = () => {
    const { data } = this.props;
    const { project } = data;
    data.moveToTaskboard(project);
  };

  submitStatusUpdate = () => {
    const { data } = this.props;
    const { statusUpdateText } = this.state;
    axios
      .post(`/projects/${data.project.id}/create_prospect_event.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        prospect_event: {
          user_id: data.currentUser.id,
          message: statusUpdateText,
          event_type: "message",
        },
      })
      .then((res) => {
        // eslint-disable-next-line no-undef
        M.toast({ html: "Status added", classes: "green" });
        data.loadProspects(true);
        const { prospectEvents } = this.state;
        prospectEvents.push(res.data);
        this.setState({ prospectEvents, statusUpdateText: "" });
      })
      .catch((err) => {
        console.error(err);
        // eslint-disable-next-line no-undef
        M.toast({ html: "Error adding status", classes: "red" });
      });
  };

  editContactHandler = (contact) => {
    axios
      .patch(`/contacts/${contact.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        contact: {
          name: contact.name,
          email: contact.email,
        },
      })
      .then((res) => {
        this.contactHandler("edit", res.data);
        // eslint-disable-next-line no-undef
        M.toast({
          html: `Contact updated`,
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch((err) => console.error(err));
  };

  addContactHandler = (contact) => {
    const { data } = this.props;
    axios
      .post(`/contacts.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        contact: {
          name: contact.name,
          email: contact.email,
          user_id: data.currentUser.id,
          project_id: data.project.id,
          invoicing: contact.type === INVOICING,
        },
      })
      .then((res) => {
        this.contactHandler("create", res.data);
        // eslint-disable-next-line no-undef
        M.toast({
          html: `Contact updated`,
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch((err) => console.error(err));
  };

  addInvoiceContactHandler = (type, name, email) => {
    const { data } = this.props;
    axios
      .post(`/contacts.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        contact: {
          name,
          job: "",
          phone_number: "",
          email,
          user_id: data.currentUser.id,
          project_id: data.project.id,
          invoicing: true,
        },
      })
      .then((res) => {
        this.contactHandler("create", res.data);
        // eslint-disable-next-line no-undef
        M.toast({
          html: `Contact Added`,
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch((err) => console.error(err));
  };

  deleteContactHandler(contact) {
    axios
      .delete(`/contacts/${contact.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        this.contactHandler("delete", contact);
        // eslint-disable-next-line no-undef
        M.toast({
          html: `Contact Deleted`,
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch((err) => console.error(err));
  }

  handleInputChange(e) {
    const { name } = e.target;
    this.setState({ [name]: e.target.value });
  }

  getStage = () => {
    const { data, prospectsHandler } = this.props;
    switch (data.column) {
      case LEADING:
        return (
          <LeadStage
            submitHandler={this.submitFileData}
            project={data.project}
            tagOptions={data.tagOptions}
          />
        );
      case EST_REQUESTING:
        return (
          <EstimateRequest
            submitHandler={this.submitFileData}
            project={data.project}
          />
        );
      case EST_SENDING:
        return (
          <EstimateSent
            submitHandler={this.submitData}
            project={data.project}
          />
        );
      case PROJECT_CONFIRMING:
        return (
          <ProjectConfirmed
            submitHandler={this.submitData}
            project={data.project}
          />
        );
      case TESTING:
        return <InvoiceReady />;
      case COMPLETING:
        return <InvoiceReady />;
      case READYING:
        return <InvoiceReady />;
      case INVOICING:
        return <InvoiceReady />;
      case PROSPECT:
        return (
          <ProspectStage
            submitHandler={this.submitData}
            project={data.project}
            currentUser={data.currentUser}
            projectUpdater={data.projectUpdater}
            prospectsHandler={prospectsHandler}
            updateProjectProspect={this.updateProjectProspect}
            moveToTaskboard={this.moveToTaskboard}
          />
        );
      default:
        return (
          <LeadStage submitHandler={this.submitData} project={data.project} />
        );
    }
  };

  closeModal = () => {
    const { modalAction } = this.props;
    modalAction(false, "", {});
  };

  dropCard = () => {
    const { data } = this.props;
    const { project } = data;
    Swal.fire({
      title: `Drop Card?`,
      text: `Are you sure that you want to drop this card from the board?`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        axios
          .post(`projects/${project.id}/drop_card`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            if (data.column === PROSPECT) {
              data.dropProspect(project.id, true);
            } else {
              data.projectHandler(
                project.project_status.aasm_state,
                "dropping",
                res.data
              );
            }
            this.closeModal();
          })
          .catch((err) => {
            // eslint-disable-next-line no-undef
            M.toast({ html: err, displayLength: 3000, classes: "red" });
            console.error(err);
          });
      }
    });
  };

  undropCard = () => {
    const { data } = this.props;
    axios
      .post(`projects/${data.project.id}/undrop_card`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (data.column === PROSPECT) {
          data.dropProspect(data.project.id, false);
        } else {
          data.projectHandler(
            data.project.project_status.aasm_state,
            "dropping",
            res.data
          );
        }
        this.closeModal();
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        M.toast({ html: err, displayLength: 3000, classes: "red" });
        console.error(err);
      });
  };

  submitFileData = (data, prevStage, nextStage, save) => {
    const { props, closeModal } = this;
    $.ajax({
      url: `/projects/${props.data.project.id}.json`,
      enctype: "multipart/form-data",
      type: "PATCH",
      data,
      processData: false,
      contentType: false,
      cache: false,
      beforeSend(xhr) {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      success(res) {
        if (save) {
          // eslint-disable-next-line no-undef
          M.toast({ html: "Saved", classes: "green" });
          props.data.projectHandler(prevStage, prevStage, res);
          closeModal();
        } else {
          // eslint-disable-next-line no-undef
          M.toast({ html: "Project Stage Updated", classes: "green" });
          this.updateProjectStatus(
            props.data.project.id,
            props.data.project.project_status.id,
            prevStage,
            nextStage
          );
          closeModal();
        }
      },
      error(errorData) {
        // If error present error swal message
        console.error(errorData);
      },
    });
  };

  updateProjectStatus = (id, projectStatusId, oldState, newState) => {
    const { data } = this.props;
    const { project } = data;
    axios
      .patch(`/projects/${id}/update_state`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        project_status: {
          old_state: oldState,
          new_state: newState,
          id: projectStatusId,
        },
      })
      .then((res) => {
        data.stateCheckHandler(oldState, newState, project);
        data.projectHandler(oldState, newState, res.data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        M.toast({
          html: err.response.data,
          displayLength: 3000,
          classes: "red",
        });
        console.error(err);
      });
  };

  backToTesting = () => {
    const { data } = this.props;
    const { project } = data;
    this.updateProjectStatus(
      project.id,
      project.project_status.id,
      INVOICING,
      "testing"
    );
    // eslint-disable-next-line no-undef
    M.toast({ html: "Moved back to testing", classes: "green" });
    this.closeModal();
  };

  submitData = (data, prevStage, nextStage, save) => {
    const { data: dataProps } = this.props;
    const { project } = dataProps;
    axios
      .patch(`/projects/${project.id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        project: data,
      })
      .then((res) => {
        if (save) {
          // eslint-disable-next-line no-undef
          M.toast({ html: "Saved", classes: "green" });
          dataProps.projectHandler(prevStage, prevStage, res.data);
          this.closeModal();
        } else {
          this.updateProjectStatus(
            project.id,
            project.project_status.id,
            prevStage,
            nextStage
          );
          // eslint-disable-next-line no-undef
          M.toast({ html: "Submitted", classes: "green" });
          dataProps.loadRegular();
          this.closeModal();
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        M.toast({ html: err.response.data, classes: "red" });
        console.error(err);
      });
  };

  submitMonthlyData = (data) => {
    const {
      refreshProjects,
      data: { project },
    } = this.props;

    axios
      .patch(`/projects/${project.id}.json`, { project: data })
      .then(() => {
        M.toast({ html: "Saved", classes: "green" });
        refreshProjects?.(); // Trigger refresh if callback exists
      })
      .catch(({ response }) => {
        M.toast({ html: response?.data || "Error", classes: "red" });
        console.error(response);
      });
  };

  updateSearchHandler = (e) => {
    const { data } = this.props;
    const nextComments = this.state.comments.filter((comment) =>
      `${comment.text.toLowerCase()} ${comment.user.name.toLowerCase()}`.includes(
        e.target.value
      )
    );
    this.setState({
      searchComments: nextComments,
      commentsSearch: e.target.value,
    });
  };

  updateProjectProspect = (data) => {
    const { data: dataProps } = this.props;
    const { project } = dataProps;
    axios
      .patch(`/projects/${project.id}.json?prospect=true`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        project: data,
      })
      .then(() => {
        // eslint-disable-next-line no-undef
        M.toast({ html: "Saved", classes: "green" });
        dataProps.loadProspects(true);
        this.closeModal();
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        M.toast({ html: err.res.data, classes: "red" });
        console.error(err);
      });
  };

  updateClientName = (e) => {
    this.setState({ clientName: e.target.value });
  };

  static passFocus(e) {
    if (e.target && e.target.previousSibling) {
      e.target.previousSibling.focus();
    }
  }

  copyProject = () => {
    const { data } = this.props;
    data.copyProject();
  };

  contactHandler = (action, contact) => {
    const { contacts } = this.state;
    let newContacts = [...contacts];
    if (action === "create") {
      newContacts = [contact, ...newContacts];
    } else if (action === "delete" || action === "edit") {
      for (let i = 0; i < newContacts.length; i += 1) {
        if (newContacts[i].id === contact.id) {
          if (action === "delete") {
            newContacts.splice(i, 1);
          } else if (action === "edit") {
            newContacts.splice(i, 1, contact);
          }
          break;
        }
      }
    }
    this.setState({ contacts: newContacts });
  };

  focusMount = () => {
    const { data } = this.props;
    if (data.focusTarget && data.focusTarget !== "") {
      if (data.focusTarget === "comments") {
        const clickElem = document.getElementById("comments");
        if (clickElem) {
          setTimeout(() => {
            clickElem.click();
          }, 400);
        }
      }
    }
  };

  saveClientName = () => {
    const { data, loadRegular } = this.props;
    const { project } = data;
    const { clientName } = this.state;
    axios
      .patch(`/clients/${project.client_id}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        client: {
          name: clientName,
        },
      })
      .then(() => {
        this.setState({ clientEdit: false });
        loadRegular();
        // eslint-disable-next-line no-undef
        M.toast({
          html: `Client name updated`,
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch((err) => console.error(err));
  };

  toggleClientEdit = (e) => {
    if (e && e.target.value === "cancel") {
      this.setState({ clientEdit: false });
    } else {
      this.setState((prevState) => {
        return { clientEdit: !prevState.clientEdit };
      });
    }
  };

  commentHandler(action, comment) {
    const { comments } = this.state;
    let newComments = [...comments];
    if (action === "create") {
      newComments = [comment, ...newComments];
    } else if (action === "delete" || action === "edit") {
      for (let i = 0; i < newComments.length; i += 1) {
        if (newComments[i].id === comment.id) {
          if (action === "delete") {
            newComments.splice(i, 1);
          } else if (action === "edit") {
            newComments.splice(i, 1, comment);
          }
          break;
        }
      }
    }
    this.setState({ comments: newComments });
  }

  commentSubmit(e) {
    e.preventDefault();
    var self = this;
    if (this.state.commentInput === "") {
      return;
    }
    axios
      .post(`/comments.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        comment: {
          text: self.state.commentInput,
          user_id: this.state.currentUser.id,
          project_id: this.state.project.id,
        },
      })
      .then((res) => {
        this.setState({ commentInput: "" });
        //if prospect is true our comment response is in res.data.comment else comment is in res.data.
        if (this.props.data.column === "prospect") {
          this.commentHandler("create", res.data.comment);
        } else {
          this.commentHandler("create", res.data);
        }
        M.toast({
          html: `<span aria-live='assertive'>Comment created</span>`,
          displayLength: 3000,
          classes: "green",
        });
        document.activeElement.blur();
      })
      .catch((err) => console.error(err));
  }
  handleCheckBoxes = () => {
    this.setState(
      (prevState) => ({
        project: {
          ...prevState.project,
          monthly_invoice: !prevState.project.monthly_invoice,
        },
        monthly_invoice: !prevState.monthly_invoice,
      }),
      () => {
        this.submitMonthlyData(this.state.project);
      }
    );
  };

  render() {
    const { data, setBackdropLoader } = this.props;
    const { project, currentUser, column, copyProject } = data;
    const {
      commentsSearch,
      searchComments,
      comments: commentsList,
      prospectEvents,
      statusUpdateText,
      clientEdit,
      clientName,
      contacts,
      commentInput,
    } = this.state;

    const comments = commentsSearch !== "" ? searchComments : commentsList;

    const cardAccess =
      currentUser.role_id >= 4 || project.access_ids.includes(currentUser.id);

    return (
      <div>
        <div
          style={{
            padding: "32px",
            paddingBottom: 0,
            borderRadius: "10px",
          }}
        >
          <div
            style={{ position: "relative" }}
            className="flex-container flex-al-center flex-j-start"
          >
            <h4
              className="taskCardTitle"
              style={{ flex: 1, marginBottom: "8px" }}
            >
              {clientEdit ? (
                <div className="flex-al-center flex-j-start flex-container">
                  <input
                    className="manrope"
                    style={{
                      fontSize: column === PROSPECT ? "22px" : "16px",
                      lineHeight: "16px",
                      fontWeight: 500,
                      margin: "0 0 15px 0",
                      height: "20px",
                    }}
                    type="text"
                    onChange={this.updateClientName}
                    value={clientName}
                  />
                  <button
                    type="button"
                    onClick={this.saveClientName}
                    className="budget btn btn-flat"
                    aria-label="Save Budget"
                    style={{
                      display: "block",
                      height: "22px",
                      backgroundColor: "white",
                      margin: "0 0 15px 0",
                      padding: "1px 6px",
                    }}
                  >
                    <i
                      className="material-icons"
                      style={{
                        color: "#519acc",
                        fontSize: "21px",
                        lineHeight: "20px",
                      }}
                    >
                      done
                    </i>
                  </button>
                  <button
                    type="button"
                    style={{ height: "22px", margin: "0 0 15px 0" }}
                    value="cancel"
                    className="btn btn-flat"
                    onClick={this.toggleClientEdit}
                  >
                    <i
                      className="material-icons"
                      style={{
                        color: "#519acc",
                        fontSize: "21px",
                        lineHeight: "20px",
                      }}
                    >
                      close
                    </i>
                  </button>
                </div>
              ) : (
                <div className="btn-hvr-container flex-al-center flex-j-start flex-container">
                  <p
                    className="manrope semi-bold"
                    style={{
                      fontSize: "16px",
                      lineHeight: "22px",
                      margin: 0,
                    }}
                  >
                    {clientName}
                  </p>
                  <button
                    type="button"
                    style={{
                      height: "16px",
                      padding: "1px 6px",
                    }}
                    className="filter-btn btn btn-flat"
                    onClick={this.toggleClientEdit}
                  >
                    <EditIcon
                      svgStyles={{ pointerEvents: "none" }}
                      height={16}
                      width={16}
                    />
                  </button>
                </div>
              )}
              {!(column === PROSPECT) && (
                <p
                  className="manrope"
                  style={{
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 600,
                    margin: 0,
                    marginTop: "8px",
                  }}
                >
                  {project.name}
                </p>
              )}
            </h4>
            <button
              type="button"
              id="modalClose"
              aria-label="Close Modal"
              className="btn-icon"
              style={{
                padding: 0,
                position: "relative",
                top: "-15px",
                right: 0,
              }}
              onClick={this.closeModal}
            >
              <ExitIcon
                svgStyles={{ pointerEvents: "none" }}
                height="25px"
                width="25px"
              />
            </button>
          </div>
          {!(column === PROSPECT) && (
            <ms.ProjectStageTagRow
              style={{ marginBottom: "32px", display: "flex" }}
            >
              <ModalTag column={column} tag={project.tag} />
              {this.state.project.monthly_invoice && (
                <ModalTag
                  column={"monthly"}
                  tag={{ id: 1, name: "Monthly", color: "#ca327a" }}
                />
              )}
            </ms.ProjectStageTagRow>
          )}
          {cardAccess && (
            <TeamAdd
              leadOptions={data.leadOptions}
              projectId={project.id}
              projectLeads={project.leads}
              projectEstimateOwner={{
                avatar_url: project.estimate_owner_avatar_url,
                id: project.estimate_owner_id,
                name: project.estimate_owner_name,
              }}
              projectLeadOwner={{
                avatar_url: project.lead_owner_avatar_url,
                id: project.lead_owner_id,
                name: project.lead_owner_name,
              }}
              detailView
              prospect={column === PROSPECT}
              currentUser={currentUser}
              projectUpdater={data.projectUpdater}
              projectHandler={data.projectHandler}
              loadProspects={data.loadProspects}
              stage={column}
            />
          )}
        </div>
        <div id="project-stage-modal" className="modal-content">
          {cardAccess && this.getStage()}
          {!(column === PROSPECT) &&
            project.client_parent === false &&
            project.billable && (
              <ProjectDeets
                allowEdit={cardAccess}
                stage={column}
                project={project}
                currentUser={currentUser}
                projectUpdater={data.projectUpdater}
              />
            )}
          {currentUser.role_id >= 3 &&
            STAGES.filter(
              (stage) =>
                ![
                  LEADING,
                  EST_REQUESTING,
                  EST_SENDING,
                  PROJECT_CONFIRMING,
                  PROSPECT,
                ].includes(stage)
            ).includes(column) && (
              <InvoiceDetails
                stage={column}
                project={project}
                invoice={project.last_invoiced}
                currentUser={currentUser}
                projectUpdater={data.projectUpdater}
                projectHandler={data.projectHandler}
                updateProjectStatus={this.updateProjectStatus}
                setBackdropLoader={setBackdropLoader}
                editContactHandler={this.editContactHandler}
                deleteContactHandler={this.deleteContactHandler}
                submitHandler={this.editContactHandler}
                addContactHandler={this.addContactHandler}
                closeModal={this.closeModal}
                contacts={contacts.filter((x) => x.invoicing === true)}
              />
            )}
          {column === PROSPECT && (
            <ProspectTimeline
              prospectEvents={prospectEvents}
              column={column}
              project={project}
              statusUpdateText={statusUpdateText}
              handleInputChange={this.handleInputChange}
              submitStatusUpdate={this.submitStatusUpdate}
            />
          )}
          <DropperContainer
            dropperId="comments"
            parentScrollId="project-stage-modal"
            title="Comments"
          >
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxHeight: "800px",
                  overflow: "auto",
                  padding: "16px",
                }}
              >
                <div className="flex-container flex-al-center">
                  <div
                    id="ticketSearch"
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <i
                      aria-hidden="true"
                      style={{
                        position: "absolute",
                        pointerEvents: "none",
                        left: "6px",
                        color: "#ababab",
                      }}
                      className="material-icons prefix"
                    >
                      search
                    </i>
                    <input
                      id="bug-search-input"
                      className="browser-default"
                      type="text"
                      onChange={this.updateSearchHandler}
                      placeholder=" "
                      aria-live="polite"
                      value={this.commentsSearch}
                      aria-label="Search"
                    />
                  </div>
                </div>
                <form
                  id="modal-comments-form"
                  onSubmit={this.commentSubmit}
                  className="flex-container flex-al-center flex-row"
                  style={{ width: "100%", marginBottom: "16px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <textarea
                      style={{
                        flex: "1",
                        overflow: "auto",

                        maxHeight: `${commentInput !== "" ? "175px" : "45px"}`,
                        transition: "all .4s",
                        borderRadius: "5px",
                        paddingRight: "100px",
                      }}
                      className="browser-default text-input-v2"
                      name="commentInput"
                      type="text"
                      rows="4"
                      placeholder="Add a comment..."
                      value={commentInput}
                      onChange={this.handleInputChange}
                    />
                    <button
                      type="submit"
                      style={{
                        opacity: `${commentInput !== "" ? "1" : "0"}`,
                        position: "absolute",
                        bottom: "26px",
                        right: "16px",
                        padding: "14px 20px",
                        color: "white",
                        backgroundColor: "#519ACC",
                        borderRadius: "5px",
                        cursor: "pointer",
                        transition: "all .4s",
                        display: "flex",
                        alignItems: "center",
                        boxShadow: "0px 3px 0px 0px #578BC2",
                        border: "none",
                        fontFamily: "arial",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
                <div
                  style={{
                    display: "flex",
                    flex: "1 1 auto",
                    gap: "16px",
                    flexDirection: "column",
                  }}
                >
                  {comments.map((comment, index) => (
                    <div style={{ flex: 1, height: "100%" }} key={comment.id}>
                      <ProjectComment
                        handler={this.commentHandler}
                        comment={comment}
                        currentUser={currentUser}
                        prospect={column === PROSPECT}
                        loadProspects={data.loadProspects}
                      />
                    </div>
                  ))}
                  {searchComments.length === 0 && commentsSearch !== "" && (
                    <p style={{ color: "lightgray", padding: "20px 0 0 5px" }}>
                      No comments for that query
                    </p>
                  )}
                </div>
              </div>
            </div>
          </DropperContainer>
          <DropperContainer
            style={{ margin: "10px 0 0" }}
            parentScrollId="project-stage-modal"
            title="Client Contact(s)"
          >
            <div style={{ padding: "16px" }}>
              <ProjectContacts
                fullWidth
                type="regular"
                deleteContactHandler={this.deleteContactHandler}
                submitHandler={this.editContactHandler}
                addContactHandler={this.addContactHandler}
                contacts={contacts.filter((x) => x.invoicing === false)}
              />
            </div>
          </DropperContainer>
          {!(column === PROSPECT) &&
            prospectEvents &&
            prospectEvents.length > 0 && (
              <ProspectTimeline
                column={column}
                project={project}
                prospectEvents={prospectEvents}
                statusUpdateText={statusUpdateText}
                handleInputChange={this.handleInputChange}
                submitStatusUpdate={this.submitStatusUpdate}
              />
            )}

          {STAGES.filter(
            (stage) =>
              ![
                LEADING,
                EST_REQUESTING,
                EST_SENDING,
                PROJECT_CONFIRMING,
                PROSPECT,
              ].includes(stage)
          ).includes(column) && (
            <InvoiceFormItem>
              <input
                name="monthly_invoice"
                className="filled-in"
                checked={this.state.project.monthly_invoice}
                type="checkbox"
              />
              <span
                onClick={this.handleCheckBoxes}
                className="invoice-checkbox"
              >
                Invoiced Monthly
              </span>
            </InvoiceFormItem>
          )}

          <div style={{ marginTop: "32px" }}>
            {copyProject &&
              !(column === PROSPECT) &&
              project.client_parent === false && (
                <button
                  type="button"
                  style={{
                    paddingLeft: "0",
                    paddingRight: "0",
                    marginRight: "20px",
                  }}
                  className="btn btn-flat"
                  onClick={this.copyProject}
                >
                  Duplicate Project
                </button>
              )}
            {column !== INVOICING && project.active && (
              <button
                type="button"
                style={{ paddingLeft: "0", paddingRight: "0" }}
                className="btn btn-flat"
                onClick={this.dropCard}
              >
                Drop Card
              </button>
            )}
            {column !== INVOICING && !project.active && (
              <button
                type="button"
                style={{ paddingLeft: "0", paddingRight: "0" }}
                className="btn btn-flat"
                onClick={this.undropCard}
              >
                Undrop Card
              </button>
            )}
            {column === INVOICING && (
              <>
                <button
                  type="button"
                  style={{
                    paddingLeft: "0",
                    paddingRight: "0",
                    marginLeft: "0px",
                  }}
                  className="btn btn-flat"
                  onClick={this.dropCard}
                >
                  Drop Card
                </button>
                <button
                  type="button"
                  style={{
                    paddingLeft: "0",
                    paddingRight: "0",
                    marginLeft: "20px",
                  }}
                  className="btn btn-flat"
                  onClick={this.backToTesting}
                >
                  Move To Testing
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const InvoiceFormItem = styled(ms.FormItem)`
  span.invoice-checkbox {
    ${ms.h3Styles}
    display: inline-block;
    white-space: nowrap;
    height: 100%;
    width: 100%;
    padding-left: 30px;
    padding-bottom: 3px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 13px;
    line-height: 19px;
    max-width: 145px;
    margin-top: 10px;
  }
`;
export default ProjectStageModal;
