import axios from "axios";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { DragDropContext } from "react-beautiful-dnd";
import styled from "styled-components";
import ProjectStatusBoardIcon from "../icons/project_status_board_icon";
import ReactModal from "../modals/react_modal";
import { sentryInit } from "../utils";
import ProspectTaskCard from "./prospect_task_card";
import TaskboardColumn from "./taskboard_column";
import userTypes from "../../types/user";
import projectTypes from "../../types/taskboardProject";

sentryInit();

function getColumnKeys() {
  return [
    "leading",
    "est_requesting",
    "est_sending",
    "project_confirming",
    "testing",
    "completing",
    "readying",
    "invoicing",
  ];
}

const indexMap = {
  leading: 0,
  est_requesting: 1,
  est_sending: 2,
  project_confirming: 3,
  testing: 4,
  completing: 5,
  readying: 6,
  invoicing: 7,
};

function getInitialState(projects) {
  axios.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-TOKEN": document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content"),
  };
  const columnKeys = [
    "leading",
    "est_requesting",
    "est_sending",
    "project_confirming",
    "testing",
    "completing",
    "readying",
    "invoicing",
  ];
  return {
    columnKeys,
    projects,
  };
}

function reducer(state, action) {
  if (action.type === "REORDER") {
    return {
      projects: action.payload,
      columnKeys: getColumnKeys(action.payload),
    };
  }
  if (action.type === "EDIT") {
    return {
      projects: action.payload,
      columnKeys: getColumnKeys(action.payload),
    };
  }
  if (action.type === "SEARCH") {
    return {
      projects: action.payload,
      columnKeys: getColumnKeys(action.payload),
    };
  }

  return state;
}

// eslint-disable-next-line no-unused-vars
const TaskboardContainer = (props) => {
  const {
    projects,
    clientOptions,
    leadOptions,
    userOptions,
    tagOptions,
    currentUser,
    taskboardUserOptions,
  } = props;
  const [state, dispatch] = useReducer(reducer, undefined, () =>
    getInitialState(projects)
  );
  const [isLoading, setIsLoading] = useState({});

  const [modalObject, setModalObject] = useState({
    modalOpen: false,
    currentModal: "",
    modalData: {},
  });

  const [searchInput, setSearchInput] = useState("");
  const [searchUpdatedInput, setSearchUpdatedInput] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [loadingCard, setLoadingCard] = useState(0);
  const [droppedActive, setDroppedActive] = useState(false);
  const [prospectActive, setProspectActive] = useState(false);
  const [projectProspects, setProjectProspects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assignedMe, setAssignedMe] = useState(false);
  const [activeDragColumn, setActiveDragColumn] = useState(-1);

  const setModal = useCallback((bool, page, data) => {
    setModalObject({
      modalOpen: bool,
      currentModal: page,
      modalData: data,
    });
  }, []);

  async function fetchProjects() {
    try {
      const response = await axios.get("/taskboard");
      return response.data.projects;
    } catch (error) {
      console.error("Failed to fetch projects", error);
      throw error;
    }
  }

  async function projectStateReload() {
    setIsLoading(true);
    try {
      const newProjects = await fetchProjects();

      dispatch({
        type: "REORDER",
        payload: newProjects,
      });
    } catch (error) {
      console.error("Error refreshing project data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const projectHandler = useCallback(
    (source, destination, project) => {
      const { projects: p } = state;

      const index = p[source].projects.findIndex((x) => x.id === project.id);

      if (index !== -1) {
        if (source === destination) {
          p[source].projects.splice(index, 1, project);
          p[source].total_count = p[source].total_count - 1;
          p[destination].total_count = p[destination].total_count + 1;
        } else if (destination === "dropping") {
          p[source].projects.splice(index, 1);
          p[source].total_count = p[source].total_count - 1;
        } else {
          p[destination].projects.push(project);
          p[source].projects.splice(index, 1);
          p[source].total_count = p[source].total_count - 1;
          p[destination].total_count = p[destination].total_count + 1;
        }
        dispatch({ type: "REORDER", payload: p });
      } else if (
        destination === "readying" &&
        p.testing &&
        p.testing.findIndex((x) => x.id === project.id) !== -1
      ) {
        p[destination].projects.push(project);
        p[source].total_count = p[source].total_count - 1;
        p[destination].total_count = p[destination].total_count + 1;
        p.testing.splice(
          p.testing.findIndex((x) => x.id === project.id),
          1
        );
        dispatch({ type: "REORDER", payload: p });
      }
    },
    [state]
  );

  const projectStateRefresh = useCallback(() => {
    dispatch({ type: "REORDER", payload: state.projects });
  }, [state.projects]);

  const openTransitionModal = useCallback(
    (transition, project) => {
      const customClass =
        transition === "complete_transition" ||
        transition === "readying_back_transition"
          ? "wideTaskTransitionModal"
          : "taskTransitionModal";
      setModal(true, "task-transition", {
        project,
        transition,
        required: {
          text: "The fields in this modal are required, please provide the data to proceed",
        },
        leadOptions,
        userOptions,
        currentUser,
        refreshProjects: projectStateRefresh,
        projectHandler,
        closeButton: false,
        customClass,
      });
    },
    [
      currentUser,
      leadOptions,
      projectHandler,
      projectStateRefresh,
      setModal,
      userOptions,
    ]
  );

  const checkForTransitionState = useCallback(
    (old, newState, project) => {
      const stateWord = `${old}->${newState}`;
      // names of transition are based on the originating state
      switch (stateWord) {
        case "leading->est_requesting":
          openTransitionModal("lead_transition", project);
          break;
        case "est_requesting->est_sending":
          openTransitionModal("requesting_transition", project);
          break;
        case "est_sending->project_confirming":
          openTransitionModal("sending_transition", project);
          break;
        case "testing->readying":
          openTransitionModal("complete_transition", project);
          break;
        case "completing->readying":
          openTransitionModal("complete_transition", project);
          break;
        case "invoicing->readying":
          openTransitionModal("readying_back_transition", project);
          break;
        case "readying->invoicing":
          openTransitionModal("readying_transition", project);
          break;
        default:
          break;
      }
    },
    [openTransitionModal]
  );

  async function handleOnLoadCardClick(id) {
    const response = await axios.get(`/projects/${id}/project_taskboard`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const project = response.data;
    setModal(true, "project-stage", {
      project,
      leadOptions,
      userOptions,
      tagOptions,
      column: project.project_status.aasm_state,
      customClass: "taskModal",
      stateCheckHandler: checkForTransitionState,
      currentUser,
      projectHandler,
    });
  }

  useEffect(() => {
    if (
      window.location.hash &&
      document.getElementById(window.location.hash.slice(1))
    ) {
      document.getElementById(window.location.hash.slice(1)).scrollIntoView();
    }
    const url = new URL(window.location.href);
    const selectedCard = url.searchParams.get("selected_card");
    if (selectedCard) {
      const projectIds = [].concat
        .apply([], Object.values(projects))
        .map((p) => p.id);
      if (
        !Number.isNaN(selectedCard) &&
        projectIds.indexOf(parseInt(selectedCard, 10)) !== -1
      ) {
        handleOnLoadCardClick(selectedCard);
        url.searchParams.delete("selected_card");
        window.history.replaceState(window.history.state, "", url.href);
      } else {
        // eslint-disable-next-line no-undef
        M.toast({
          html: `Project not found or is no longer on taskboard`,
          displayLength: 3000,
          classes: "red",
        });
      }
    }
  });

  const handleAssigned = useCallback(
    () => setAssignedMe(!assignedMe),
    [assignedMe]
  );

  const handleSearchUpdate = (e) => {
    const input = e.target.value;
    setSearchUpdatedInput(input);

    // Clear previous timeout if the user is still typing
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Set a new timeout to fetch data after 1 seconds
    const timeout = setTimeout(() => {
      setLoading(true);
      fetchTaskboard(input);
    }, 1000);

    setSearchTimeout(timeout);
  };

  const fetchTaskboard = async (query) => {
    try {
      const response = await axios.get("/taskboard", {
        params: {
          search_input: query,
          limit: 20,
          offset: 0,
        },
      });

      dispatch({ type: "SEARCH", payload: response.data.projects });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching taskboard data", error);
      setLoading(false);
    }
  };
  const updateProjectStatus = useCallback(
    (id, projectStatusId, oldState, newState) => {
      setLoadingCard(id);
      axios
        .patch(`/projects/${id}/update_state`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          project_status: {
            old_state: oldState,
            new_state: newState,
            id: projectStatusId,
          },
        })
        .catch((err) => {
          projectStateRefresh();
          // eslint-disable-next-line no-undef
          M.toast({
            html: "This card cannot progress into this column",
            displayLength: 3000,
            classes: "red",
          });
          console.error(err);
        })
        .finally(() => {
          setLoadingCard(0);
        });
    },
    [projectStateRefresh]
  );

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const reorderProjects = useCallback(
    ({ projects: projectsList, source, destination }) => {
      let current = [...projectsList[source.droppableId].projects];
      const next = [...projectsList[destination.droppableId].projects];
      if (searchInput) {
        current = current.filter((c) =>
          `${c.name ? c.name.toLowerCase() : ""} ${
            c.client_name ? c.client_name.toLowerCase() : ""
          }`.includes(searchInput.toLowerCase())
        );
      }
      const target = current[source.index];

      // Create new copies of the projects arrays to avoid direct mutation
      const updatedSource = [...current];
      const updatedDestination = [...next];

      // moving to same list
      if (source.droppableId === destination.droppableId) {
        const reordered = reorder(
          updatedSource,
          source.index,
          destination.index
        );
        return {
          projects: {
            ...projectsList,
            [source.droppableId]: {
              ...projectsList[source.droppableId],
              projects: reordered,
            },
          },
        };
      }

      // moving to different list
      updatedSource.splice(source.index, 1);
      updatedDestination.splice(destination.index, 0, target);
      target.project_status.aasm_state = destination.droppableId;

      updateProjectStatus(
        target.id,
        target.project_status.id,
        source.droppableId,
        destination.droppableId
      );

      checkForTransitionState(
        source.droppableId,
        destination.droppableId,
        target
      );

      return {
        projects: {
          ...projectsList,
          [source.droppableId]: {
            ...projectsList[source.droppableId],
            projects: updatedSource,
            total_count: projectsList[source.droppableId].total_count - 1,
          },
          [destination.droppableId]: {
            ...projectsList[destination.droppableId],
            projects: updatedDestination,
            total_count: projectsList[destination.droppableId].total_count + 1,
          },
        },
      };
    },
    [checkForTransitionState, searchInput, updateProjectStatus]
  );

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        setActiveDragColumn(-1);
        return;
      }

      const { source, destination } = result;

      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      ) {
        setActiveDragColumn(-1);
        return;
      }
      setActiveDragColumn(-1);
      const updated = reorderProjects({
        projects: state.projects,
        source,
        destination,
      });
      dispatch({ type: "REORDER", payload: updated.projects });
    },
    [reorderProjects, state.projects]
  );

  const projectsHandler = useCallback(
    (project) => {
      const projectsList = state.projects;
      projectsList.leading.projects.push(project);
      dispatch({ type: "REORDER", payload: projectsList });
    },
    [state]
  );

  const setProjectModal = useCallback(() => {
    setModal(true, "new-lead", {
      tagOptions,
      leadOptions,
      taskboardUserOptions,
      clientOptions,
      clientId: 0,
      currentUser,
      customClass: "taskTransitionModal",
      projectsHandler,
    });
  }, [
    clientOptions,
    currentUser,
    leadOptions,
    projectsHandler,
    setModal,
    tagOptions,
    taskboardUserOptions,
  ]);

  const prospectsHandler = useCallback(
    (action, item) => {
      const pp = projectProspects;
      if (action === "create") {
        pp.push(item);
        pp.sort((a, b) => {
          return a.client_name.toLowerCase() > b.client_name.toLowerCase()
            ? 1
            : -1;
        });
        setProjectProspects(pp);
      } else if (action === "update") {
        const index = pp.findIndex((p) => p.id === item.id);
        if (index) {
          pp.splice(index, 1, item);
          pp.sort((a, b) =>
            a.client_name.toLowerCase() > b.client_name.toLowerCase() ? 1 : -1
          );
          setProjectProspects(pp);
        }
      }
    },
    [projectProspects]
  );

  const newProjectProspectModal = useCallback(() => {
    setModal(true, "add-client", {
      prospect: true,
      closeButton: true,
      customClass: "sm-modal",
      prospectsHandler,
      backdropClickCheck: {
        title: "Discard New Client",
        text: "Are you sure that you want to discard this client?",
      },
    });
  }, [prospectsHandler, setModal]);

  const checkDroppable = useCallback((dropProps) => {
    if (dropProps.source) {
      setActiveDragColumn(indexMap[dropProps.source.droppableId]);
    } else {
      setActiveDragColumn(-1);
    }
  }, []);

  const loadRegular = useCallback(() => {
    setLoading(true);
    axios
      .get(`/taskboard`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setDroppedActive(false);
        setLoading(false);
        dispatch({ type: "REORDER", payload: res.data.projects });
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        M.toast({ html: `Server Error`, displayLength: 3000, classes: "red" });
        console.error(err);
      });
  }, []);

  const loadDropped = (reload) => {
    if (prospectActive && !(reload === true)) {
      setDroppedActive(!droppedActive);
    } else if (!droppedActive || reload === true) {
      setLoading(true);
      axios
        .get(`project/dropped_cards`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setDroppedActive(true);
          setLoading(false);
          dispatch({ type: "REORDER", payload: res.data.projects });
        })
        .catch((err) => {
          M.toast({
            html: `Server Error`,
            displayLength: 3000,
            classes: "red",
          });
          console.error(err);
        });
    } else {
      loadRegular();
    }
  };

  const loadProspects = useCallback(
    (reload) => {
      if (prospectActive && !(reload === true)) {
        setProspectActive(false);
        if (droppedActive) {
          loadDropped(true);
        } else {
          loadRegular();
        }
      } else {
        setLoading(true);
        axios
          .get(`/project/load_prospects`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            setProjectProspects(res.data.project_prospects);
            // const activeProjectProspects = res.data.project_prospects.filter(
            //   (p) => p.active
            // );
            // const droppedProjectProspects = res.data.project_prospects.filter(
            //   (p) => !p.active
            // );

            // setProjectProspects(activeProjectProspects);
            // setDroppedProjectProspects(droppedProjectProspects);

            setProspectActive(true);
            setAssignedMe(false);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            // eslint-disable-next-line no-undef
            M.toast({
              html: `Server Error`,
              displayLength: 3000,
              classes: "red",
            });
            console.error(err);
          });
      }
    },
    [prospectActive, droppedActive, loadDropped, loadRegular]
  );

  const moveToTaskboard = useCallback(
    (project) => {
      setLoading(true);
      setModal(false, "", {});
      setTimeout(() => {
        setModal(true, "new-lead", {
          tagOptions,
          leadOptions,
          taskboardUserOptions,
          clientOptions,
          clientId: 0,
          currentUser,
          customClass: "taskTransitionModal",
          projectsHandler,
          projectProspect: project,
          loadProspects,
          loadRegular,
        });
        setLoading(false);
      }, 500);
    },
    [
      setModal,
      tagOptions,
      leadOptions,
      taskboardUserOptions,
      clientOptions,
      currentUser,
      projectsHandler,
      loadProspects,
      loadRegular,
    ]
  );

  const dropProspect = useCallback(
    (id, drop) => {
      setProjectProspects((prevProjectProspects) => {
        return prevProjectProspects.map((p) => {
          if (p.id === id) {
            return { ...p, active: !drop };
          }
          return p;
        });
      });
    },
    [setProjectProspects]
  );

  const projectStatusText = useMemo(() => {
    if (prospectActive && droppedActive) {
      return "Dropped Prospective Clients";
    }
    if (prospectActive) {
      return "Prospective Clients";
    }
    if (droppedActive) {
      return "Dropped Projects";
    }
    if (assignedMe) {
      return "Project Status Board - Assigned to To Me";
    }
    return "Project Status Board";
  }, [prospectActive, droppedActive, assignedMe]);

  const areAllProjectProspectsDropped = projectProspects.every(
    (p) => !p.active
  );
  const areAllProjectProspectsActive = projectProspects.every((p) => p.active);

  const loadMoreProjects = async (columnKey, offset) => {
    setIsLoading((prevLoading) => ({ ...prevLoading, [columnKey]: true }));
    try {
      const response = await fetch(`/taskboard.json?offset=${offset}&limit=20`);
      const data = await response.json();

      state.projects[columnKey].projects = [
        ...state.projects[columnKey].projects,
        ...data.projects[columnKey].projects,
      ];
      projectStateRefresh();
    } catch (error) {
      console.error("Failed to load more projects:", error);
    } finally {
      setIsLoading((prevLoading) => ({ ...prevLoading, [columnKey]: false }));
    }
  };

  return (
    <TaskboardPageWrapper>
      {loading && (
        <div className="loading-overlay">
          <div className="page-load-spinner" />
        </div>
      )}
      <ReactModal
        isShowing={modalObject.modalOpen}
        page={modalObject.currentModal}
        data={modalObject.modalData}
        loadRegular={loadRegular}
        modalAction={setModal}
        refreshProjects={projectStateReload}
      />

      <StatusBoardContainer>
        <StatusBoardHeadline className="flex-container flex-al-center">
          <ProjectStatusBoardIcon />
          <StatusBoardTitle className="super-bold manrope">
            {projectStatusText}
          </StatusBoardTitle>
        </StatusBoardHeadline>
        <div className="flex-container  flex-col">
          {!prospectActive ? (
            <AddProjectButton
              type="button"
              className="common-button-submit"
              onClick={setProjectModal}
            >
              Add Project
            </AddProjectButton>
          ) : (
            <AddClientButton
              className="common-button-submit"
              onClick={newProjectProspectModal}
            >
              Add Client
            </AddClientButton>
          )}
          <TicketSearchInput id="ticketSearch" style={{ marginRight: "16px" }}>
            <SearchIcon aria-hidden="true" className="material-icons prefix">
              search
            </SearchIcon>
            <input
              id="bug-search-input"
              className="browser-default"
              type="text"
              onChange={handleSearchUpdate}
              placeholder=" "
              aria-live="polite"
              value={searchUpdatedInput}
              aria-label="Search"
            />
          </TicketSearchInput>
          <ViewAssignedMeButton
            type="button"
            aria-label="View Assigned To Me"
            assignedMe={assignedMe}
            droppedActive={droppedActive}
            prospectActive={prospectActive}
            disabled={prospectActive}
            onClick={(e) => {
              handleAssigned();
              e.currentTarget.blur();
            }}
            className="common-button-submit-taskboard "
          >
            Assigned To Me
          </ViewAssignedMeButton>
          <ViewProspectsButton
            prospectActive={prospectActive}
            droppedActive={droppedActive}
            assignedMe={assignedMe}
            type="button"
            className="common-button-submit-taskboard"
            disabled={assignedMe}
            onClick={(e) => {
              loadProspects();
              e.currentTarget.blur();
            }}
          >
            View Prospective
          </ViewProspectsButton>
          <ViewDroppedButton
            type="button"
            droppedActive={droppedActive}
            assignedMe={assignedMe}
            className="common-button-submit-taskboard"
            onClick={(e) => {
              loadDropped();
              e.currentTarget.blur();
            }}
          >
            View Dropped
          </ViewDroppedButton>
        </div>
      </StatusBoardContainer>

      {!prospectActive ? (
        <div id="tb-hori-scroll" style={{ height: "calc(100vh - 238px)" }}>
          <DragDropContext onDragStart={checkDroppable} onDragEnd={onDragEnd}>
            <Container>
              {state.columnKeys.map((key, index) => {
                const projectsList =
                  state.projects[key] && state.projects[key].projects
                    ? state.projects[key].projects.sort((a, b) => {
                        return new Date(b.created_at) - new Date(a.created_at);
                      })
                    : [];

                return (
                  <TaskboardColumn
                    key={key}
                    searchInput={searchUpdatedInput}
                    assignedMe={assignedMe}
                    loadingCard={loadingCard}
                    columnIndex={index}
                    activeDragColumn={activeDragColumn}
                    currentUser={currentUser}
                    stateCheckHandler={checkForTransitionState}
                    modalHandler={setModal}
                    leadOptions={leadOptions}
                    userOptions={userOptions}
                    taskboardUserOptions={taskboardUserOptions}
                    tagOptions={tagOptions}
                    projects={projectsList}
                    totalCount={state.projects[key].total_count}
                    projectHandler={projectHandler}
                    projectsHandler={projectsHandler}
                    columnKey={key}
                    droppedActive={droppedActive}
                    loadMoreProjects={loadMoreProjects}
                    isLoading={isLoading[key] || false}
                  />
                );
              })}
            </Container>
          </DragDropContext>
        </div>
      ) : (
        <div
          style={{
            height: "calc(100vh - 215px)",
            backgroundColor: "#fff",
            margin: "0px 0px",
            border: "solid 1px #d7d7d7",
          }}
        >
          {!droppedActive ? (
            <>
              {areAllProjectProspectsDropped && !droppedActive ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "calc(100vh - 217px)",
                  }}
                >
                  No Prospective Clients
                </div>
              ) : (
                <div
                  style={{
                    padding: "15px",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "4px",
                  }}
                >
                  {projectProspects
                    .filter((p) => !p.active === droppedActive)
                    .map((p) => {
                      return (
                        <ProspectTaskCard
                          modalHandler={setModal}
                          key={p.id}
                          projectProspect={p}
                          prospectsHandler={prospectsHandler}
                          leadOptions={leadOptions}
                          userOptions={userOptions}
                          currentUser={currentUser}
                          loadProspects={loadProspects}
                          moveToTaskboard={moveToTaskboard}
                          dropProspect={dropProspect}
                          style={{
                            flex: "1 1 calc(33.33% - 8px)",
                            margin: "4px",
                          }}
                        />
                      );
                    })}
                </div>
              )}
            </>
          ) : (
            <>
              {areAllProjectProspectsActive && droppedActive ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "calc(100vh - 217px)",
                  }}
                >
                  No Dropped Prospective Clients
                </div>
              ) : (
                <div
                  style={{
                    padding: "15px",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "4px",
                  }}
                >
                  {projectProspects
                    .filter((p) => !p.active === droppedActive)
                    .map((p) => {
                      return (
                        <ProspectTaskCard
                          modalHandler={setModal}
                          key={p.id}
                          projectProspect={p}
                          prospectsHandler={prospectsHandler}
                          leadOptions={leadOptions}
                          userOptions={userOptions}
                          currentUser={currentUser}
                          loadProspects={loadProspects}
                          moveToTaskboard={moveToTaskboard}
                          dropProspect={dropProspect}
                          style={{
                            flex: "1 1 calc(33.33% - 8px)",
                            margin: "4px",
                          }}
                        />
                      );
                    })}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </TaskboardPageWrapper>
  );
};

const SearchIcon = styled.i`
  position: absolute;
  pointer-events: none;
  left: 6px;
  color: #ababab;
`;

const TicketSearchInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

const ViewDroppedButton = styled.button`
  padding: 10px;
  border: ${(props) => {
    return props.droppedActive ? "1px solid #519acc" : "1px solid #d7d7d7";
  }};
    
  opacity: props.assignedMe ? "0.5" : "1"
  //props.assignedMe ? "0.5" : "1"
`;

const ViewAssignedMeButton = styled.button`
  padding: 10px;
  margin-right: 8px;
  border: ${(props) => {
    return props.assignedMe ? "1px solid #519acc" : "1px solid #d7d7d7";
  }};
`;
const ViewProspectsButton = styled.button`
  padding: 10px;
  margin-right: 8px;
  border: ${(props) => {
    return props.prospectActive ? "1px solid #519acc" : "1px solid #d7d7d7";
  }};
`;

const AddClientButton = styled.button`
  margin-right: 20px;
`;

const AddProjectButton = styled.button`
  margin-right: 16px;
`;

const StatusBoardTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  font-family: Manrope;
  margin: 0px 0px 0px 16px;
  line-height: 14px;
`;

const StatusBoardHeadline = styled.div`
  margin-bottom: 16px;
`;

const StatusBoardContainer = styled.div`
  padding: 0px 0px 25px 0px;
`;

const TaskboardPageWrapper = styled.div`
  margin: 32px 32px 0px;
  height: calc(100vh - 128px);
`;

const Container = styled.div`
  display: flex;
  min-height: 69vh;
`;

// prop types. making these available for export to any child component that needs them
const clientOptions = PropTypes.arrayOf(
  PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })
);
const currentUser = userTypes;
const leadOptions = PropTypes.arrayOf(
  PropTypes.shape({
    role_id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.number,
    label: PropTypes.string,
    avatar_url: PropTypes.string,
  })
);
const projects = PropTypes.shape({
  completing: PropTypes.arrayOf(projectTypes),
  est_requesting: PropTypes.arrayOf(projectTypes),
  est_sending: PropTypes.arrayOf(projectTypes),
  invoicing: PropTypes.arrayOf(projectTypes),
  leading: PropTypes.arrayOf(projectTypes),
  project_confirming: PropTypes.arrayOf(projectTypes),
  readying: PropTypes.arrayOf(projectTypes),
  testing: PropTypes.arrayOf(projectTypes),
});
const tagOptions = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.number,
    color: PropTypes.string,
    label: PropTypes.string,
  })
);
const taskboardUserOptions = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
    name: PropTypes.string,
  })
);
const userOptions = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    role_id: PropTypes.number,
    avatar_file_name: PropTypes.string,
    avatar_updated_at: PropTypes.string,
    additional_permissions: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  })
);

export const TaskboardTypes = {
  clientOptions,
  currentUser,
  leadOptions,
  projects,
  tagOptions,
  taskboardUserOptions,
  userOptions,
};

TaskboardContainer.propTypes = {
  clientOptions,
  currentUser,
  leadOptions,
  projects,
  tagOptions,
  taskboardUserOptions,
  userOptions,
};

export default TaskboardContainer;
