import React from "react";
import moment from "moment";
import axios from "axios";
import PageNav from "../common/page_nav";
import { SingleDatePicker } from "react-dates";
import RoundDropdown from "../projects/round_dropdown";
import ScheduleDateHeaders from "../master_schedule/schedule_date_headers";
import CalendarIcon from "../icons/calendar_icon.jsx";
import ScheduleUserRow from "../master_schedule/schedule_user_row";
import ReactModal from "../modals/react_modal";
import Swal from "sweetalert2";
import UserShowSidebar from "./user_show_sidebar";
import UserHeader from "./UserHeader";
import UserForm from "./UserForm";
import { normalizePhone } from "../utils";
import Table from "./Table";
class UserShow extends React.Component {
  constructor(props) {
    super(props);
    var phone = props.user.phone ? normalizePhone(props.user.phone) : "";
    this.state = {
      user: this.props.user,
      currentUserRole: this.props.user.id,
      userName: this.props.user.name,
      email: this.props.user.email,
      secondEmail: this.props.user.secondary_email || "",
      userImage: this.props.user.avatar_url,
      fullTime: this.props.user.full_time,
      roleId: this.props.user.role_id,
      taskboardAccess:
        this.props.user.additional_permissions.includes("taskboard"),
      bookingConfirm: this.props.user.receive_confirmation,
      phone: phone || "",
      imageFile: "",
      twoWeeks: false,
      currentWeekStart: moment()
        .startOf("week")
        .add(1, "d")
        .format("YYYY-MM-DD"),
      currentWeekEnd: moment().endOf("week").format("YYYY-MM-DD"),
      tasks: [],
      contractor: this.props.user.contractor,
      closures: this.props.closures,
      pickerFocus: false,
      pickerDate: moment(new Date()),
      modalOpen: false,
      currentModal: "",
      modalData: {},
      timeEntries: this.props.timeEntries,
      // userSickPto: this.props.userSickPto,
      defaultWorkFromHome: this.props.user.default_work_from_home,
      currentView: 0,
    };
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    this.getUserTasks(this.state.currentWeekStart, this.state.currentWeekEnd);
    this.clickListener = (e) => {
      if (this.state.updated && e.target.tagName === "A") {
        var elem = e.target;
        e.preventDefault();
        Swal.fire({
          title: "Discard Changes",
          text: "You have unsaved changes. Are you sure you want to leave this page?",
          showCancelButton: true,
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            window.removeEventListener("click", this.clickListener);
            elem.click();
          }
        });
      }
    };
    document.title = `Syncit - ${this.props.user.name}`;
    this.beforeUnload = () => {
      var message = "Are you sure?";
      return message;
    };
    this.popStateListener = (e) => {
      if (this.state.updated) {
        if (
          window.confirm(
            "Would you like to save your changes before leaving this page?"
          )
        ) {
          this.handleSubmit("unload");
        }
      }
    };
    window.addEventListener("click", this.clickListener);
    window.addEventListener("popstate", this.popStateListener);
    window.onbeforeunload = null;
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.clickListener);
    window.removeEventListener("popstate", this.popStateListener);
    window.onbeforeunload = null;
  }

  getUserTasks = (start_date, end_date) => {
    this.timeout = setTimeout(() => {
      this.setState({ loading: true });
    }, 200);
    axios
      .get(`/find_tasks/${this.props.user.id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          start_date: start_date ? start_date : this.state.currentWeekStart,
          end_date: end_date ? end_date : this.state.currentWeekEnd,
        },
      })
      .then((res) => {
        clearTimeout(this.timeout);
        this.setState({
          tasks: res.data.tasks,
          timeEntries: res.data.time_entries,
          currentWeekStart: start_date
            ? start_date
            : this.state.currentWeekStart,
          currentWeekEnd: end_date ? end_date : this.state.currentWeekEnd,
          loading: false,
        });
      })
      .catch((err) => {
        clearTimeout(this.timeout);
        this.setState({ loading: false });
        console.error(err);
      });
  };

  handleAdditionPermission = (e) => {
    if (e.target.dataset.true === "true") {
      this.setState({ taskboardAccess: true, updated: true }, () => {
        if (this.state.updated && !window.onbeforeunload) {
          window.onbeforeunload = this.beforeUnload;
        }
      });
    } else {
      this.setState({ taskboardAccess: false, updated: true }, () => {
        if (this.state.updated && !window.onbeforeunload) {
          window.onbeforeunload = this.beforeUnload;
        }
      });
    }
  };

  handleSubmit = (e) => {
    if (e !== "unload") {
      e.preventDefault();
    }

    // Validation
    if (this.state.phone.length < 14 && this.state.phone.length !== 0) {
      this.showToast("Enter a valid phone number", "red");
      return;
    }

    if (!this.validateEmail(this.state.email)) {
      this.showToast("Enter a valid primary email", "red");
      return;
    }

    if (this.state.secondEmail && !this.validateEmail(this.state.secondEmail)) {
      this.showToast("Enter a valid secondary email", "red");
      return;
    }

    // Form data
    const user = new FormData();
    if (this.state.imageFile) {
      user.append("avatar", this.state.imageFile);
    }
    if (this.state.phone.length > 0) {
      user.append("phone", this.state.phone);
    }
    user.append("name", this.state.userName);
    user.append("email", this.state.email);
    user.append("default_work_from_home", this.state.defaultWorkFromHome);
    user.append("role_id", this.state.roleId);
    user.append(
      "additional_permissions[]",
      this.state.taskboardAccess ? ["taskboard"] : []
    );
    user.append(
      "secondary_email",
      this.state.secondEmail ? this.state.secondEmail : ""
    );
    user.append("contractor", this.state.contractor);
    user.append(
      "full_time",
      this.state.contractor ? false : this.state.fullTime
    );
    user.append(
      "receive_confirmation",
      !this.state.fullTime && !this.state.contractor && this.state.roleId === 1
        ? this.state.bookingConfirm
        : false
    );
    if (this.state.sickPickerDate) {
      user.append("sick_time_accrue_date", this.state.sickPickerDate);
    }

    // API call
    axios
      .patch(`/api/v1/users/${this.props.user.id}`, user, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        this.setState({ updated: false });
        this.showToast("User updated!", "green");
        window.onbeforeunload = null;
      })
      .catch((err) => {
        this.showToast("Error updating user", "red");
        console.error(err);
      });
  };

  showToast = (message, color) => {
    M.toast({
      html: message,
      displayLength: 3000,
      classes: color,
    });
  };

  setContractor = (e) => {
    this.setState({ contractor: true, updated: true }, () => {
      if (this.state.updated && !window.onbeforeunload) {
        window.onbeforeunload = this.beforeUnload;
      }
    });
  };

  setRoleId = (event) => {
    this.setState(
      { roleId: parseInt(event.target.value), updated: true },
      () => {
        if (this.state.updated && !window.onbeforeunload) {
          window.onbeforeunload = this.beforeUnload;
        }
      }
    );
  };

  setFullTime = (event) => {
    if (event.target.name === "user_full_time_true") {
      this.setState(
        { fullTime: true, contractor: false, updated: true },
        () => {
          if (this.state.updated && !window.onbeforeunload) {
            window.onbeforeunload = this.beforeUnload;
          }
        }
      );
    } else {
      this.setState(
        { fullTime: false, contractor: false, updated: true },
        () => {
          if (this.state.updated && !window.onbeforeunload) {
            window.onbeforeunload = this.beforeUnload;
          }
        }
      );
    }
  };

  setBookingConfirm = (event) => {
    this.setState(
      {
        bookingConfirm: event.target.value === "true",
        updated: true,
      },
      () => {
        if (this.state.updated && !window.onbeforeunload) {
          window.onbeforeunload = this.beforeUnload;
        }
      }
    );
  };

  deleteUser = () => {
    Swal.fire({
      title: "Delete User",
      text: "Are you sure that you want to delete this User?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`/api/v1/users/${this.props.user.id}`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            this.showToast(`${res.data} deleted`, "green");
            window.location.href = "/users";
          })
          .catch((err) => console.error(err));
      }
    });
  };

  handlePhoneChange = (e) => {
    var text = e.target.value;
    var newText = "";
    for (var x = 0; x < text.length; x++) {
      if (text.charCodeAt(x) >= 48 && text.charCodeAt(x) <= 57) {
        newText = newText + text[x];
      }
    }
    if (newText.length > 3 && newText.length < 7) {
      newText = "(" + newText.substring(0, 3) + ") " + newText.substring(3);
    } else if (newText.length >= 7) {
      newText =
        "(" +
        newText.substring(0, 3) +
        ") " +
        newText.substring(3, 6) +
        "-" +
        newText.substring(6);
    }
    this.setState(
      {
        phone: newText,
        updated: true,
      },
      () => {
        if (this.state.updated && !window.onbeforeunload) {
          window.onbeforeunload = this.beforeUnload;
        }
      }
    );
  };

  renderDateDisplay = () => {
    var displayDateStart = moment(this.state.currentWeekStart).format(
      "MM/DD/YYYY"
    );
    var displayDateEnd = moment(this.state.currentWeekEnd)
      .subtract(1, "day")
      .format("MM/DD/YYYY");
    return (
      <div
        aria-label={`Showing schedule for ${moment(displayDateStart).format(
          "dddd MMMM Do"
        )} through ${moment(displayDateEnd).format(
          "dddd MMMM Do"
        )}. Press shift+N or shift+P anywhere on the page to navigate to next or previous week, respectively.`}
        id="date-display"
      >
        {this.state.loading ? (
          <div
            aria-hidden="true"
            style={{ height: "30px", width: "30px" }}
            className="page-load-spinner"
          />
        ) : (
          <p aria-hidden="true">
            {displayDateStart} - {displayDateEnd}
          </p>
        )}
      </div>
    );
  };

  upcomingWeek = () => {
    var newWeekStart = moment(this.state.currentWeekStart.slice())
      .add(7, "d")
      .format("YYYY-MM-DD");
    var newWeekEnd = moment(this.state.currentWeekEnd.slice())
      .add(7, "d")
      .format("YYYY-MM-DD");
    this.getUserTasks(newWeekStart, newWeekEnd);
  };

  prevWeek = () => {
    var newWeekStart = moment(this.state.currentWeekStart.slice())
      .subtract(7, "d")
      .format("YYYY-MM-DD");
    var newWeekEnd = moment(this.state.currentWeekEnd.slice())
      .subtract(7, "d")
      .format("YYYY-MM-DD");
    this.getUserTasks(newWeekStart, newWeekEnd);
  };

  setTwoWeeks = (twoWeeks) => {
    this.getUserTasks(
      this.state.currentWeekStart,
      twoWeeks
        ? moment(this.state.currentWeekEnd.slice())
            .add(7, "d")
            .format("YYYY-MM-DD")
        : moment(this.state.currentWeekEnd.slice())
            .subtract(7, "d")
            .format("YYYY-MM-DD")
    );
    this.setState({
      currentWeekEnd: twoWeeks
        ? moment(this.state.currentWeekEnd.slice())
            .add(7, "d")
            .format("YYYY-MM-DD")
        : moment(this.state.currentWeekEnd.slice())
            .subtract(7, "d")
            .format("YYYY-MM-DD"),
    });
  };

  handleDateFocus = ({ focused }) => {
    this.setState({ pickerFocus: focused });
    if (focused) {
      document
        .getElementById("date-picker-container")
        .setAttribute("aria-hidden", false);
      setTimeout(() => {
        var elem = document.getElementsByClassName("DayPicker_focusRegion_1");
        elem[0].focus();
      }, 1000);
    }
  };

  onDateChangePicker = (date) => {
    this.handleDateChange(date);
    this.setState({ pickerDate: date, updated: true }, () => {
      if (this.state.updated && !window.onbeforeunload) {
        window.onbeforeunload = this.beforeUnload;
      }
    });
  };

  onFocusChangePicker = ({ focused }) => {
    this.setState({ pickerFocus: focused });
  };

  onWeekSwitchClick = (e) => {
    this.setState({ twoWeeks: !this.state.twoWeeks });
    this.setTwoWeeks(!this.state.twoWeeks);
  };

  isDayBlocked = (day) => {
    return moment(day).day() === 0 || moment(day).day() === 6 ? true : false;
  };

  setModal = (bool, page, data) => {
    this.setState({
      modalOpen: bool,
      currentModal: page,
      modalData: data,
    });
  };

  setWorkLocation = (e) => {
    this.setState({ defaultWorkFromHome: e.target.value === "true" });
  };

  handleDateChange = (date) => {
    if (moment(date).isValid()) {
      var today = moment(date);
      var weekStart = moment(today)
        .startOf("week")
        .add(1, "d")
        .format("YYYY-MM-DD");
      var weekEnd = this.state.twoWeeks
        ? moment(today).endOf("week").add(7, "d").format("YYYY-MM-DD")
        : moment(today).endOf("week").format("YYYY-MM-DD");
      this.setState({
        currentWeekStart: weekStart,
        currentWeekEnd: weekEnd,
        pickerDate: date,
      });
      this.getUserTasks(weekStart, weekEnd);
    }
  };

  renderTasks() {
    var firstDay = this.state.currentWeekStart;
    var cutoffDate = moment().day("sunday");
    var currentWeek = [...Array(5)].map((_, i) =>
      moment(firstDay).add(i, "d").format("YYYY-MM-DD")
    );
    var secondWeek = [...Array(5)].map((_, i) =>
      moment(firstDay)
        .add(i + 7, "d")
        .format("YYYY-MM-DD")
    );
    var closures = this.state.closures;
    var currentWeekClosures = Array(5).fill(false);
    var secondWeekClosures = Array(5).fill(false);
    if (closures.length > 0) {
      for (var x = 0; x < 5; x++) {
        for (var y = 0; y < closures.length; y++) {
          if (
            closures[y].start_date <= currentWeek[x] &&
            closures[y].end_date >= currentWeek[x]
          ) {
            currentWeekClosures[x] = closures[y];
          }
          if (
            closures[y].start_date <= secondWeek[x] &&
            closures[y].end_date >= secondWeek[x]
          ) {
            secondWeekClosures[x] = closures[y];
          }
        }
      }
    }
    var tasks = this.state.tasks;
    var user = this.props.user;

    return (
      <div>
        <ScheduleUserRow
          userSchedule={true}
          modalAction={this.setModal}
          user={user}
          userName={user.name}
          fullTime={user.full_time}
          userId={user.id}
          userTasks={tasks}
          index={0}
          cutoffDate={cutoffDate}
          twoWeeks={this.state.twoWeeks}
          currentWeek={currentWeek}
          secondWeek={secondWeek}
          currentWeekClosures={currentWeekClosures}
          secondWeekClosures={secondWeekClosures}
          viewOnly={true}
        />
      </div>
    );
  }

  userSchedule = () => {
    return (
      <div style={{ padding: "32px" }}>
        <PageNav
          twoWeeks={this.state.twoWeeks}
          weekHandler={this.onWeekSwitchClick}
          usedLetters={null}
          checkboxes={[]}
          scrollHandler={this.scrollIntoView}
          addHandler={null}
          addText={""}
          showSearch={false}
        />
        <div
          className="date-nav-wrapper"
          style={{ height: "140px", position: "relative", marginTop: "10px" }}
        >
          <div className="date-nav">
            <div id="date-nav-header">
              <button
                aria-hidden="true"
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  padding: "0px",
                  marginRight: "16px",
                }}
                id="prevWeek"
                onClick={this.prevWeek}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="15"
                  viewBox="0 0 10 15"
                  fill="none"
                >
                  <g clipPath="url(#clip0_3116_1576)">
                    <path
                      d="M9.29099 11.5116C10.0711 12.2791 10.213 13.5349 9.43284 14.3023C9.00731 14.7209 8.43993 15 7.94348 15C7.44702 15 6.87965 14.7907 6.52504 14.4419L0.354823 8.37209C-0.141631 7.88372 -0.141631 7.18605 0.354823 6.69768L2.41156 4.67442L6.59596 0.55814C7.3761 -0.209302 8.6527 -0.209302 9.50376 0.55814C10.2839 1.32558 10.2839 2.5814 9.50376 3.4186L5.31936 7.53488C5.31936 7.53488 5.31936 7.53488 5.31936 7.60465L9.29099 11.5116Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3116_1576">
                      <rect
                        width="10"
                        height="15"
                        fill="white"
                        transform="translate(10 15) rotate(180)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <div>{this.renderDateDisplay()}</div>
              <button
                aria-hidden="true"
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  padding: "0px",
                  marginLeft: "16px",
                }}
                id="upcomingWeek"
                onClick={this.upcomingWeek}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="15"
                  viewBox="0 0 10 15"
                  fill="none"
                >
                  <g clipPath="url(#clip0_3116_1579)">
                    <path
                      d="M0.709005 3.48837C-0.0711367 2.72093 -0.212981 1.46512 0.567161 0.697674C0.992693 0.27907 1.56007 0 2.05652 0C2.55298 0 3.12035 0.209302 3.47496 0.55814L9.64518 6.62791C10.1416 7.11628 10.1416 7.81395 9.64518 8.30233L7.58844 10.3256L3.40404 14.4419C2.6239 15.2093 1.3473 15.2093 0.496239 14.4419C-0.283903 13.6744 -0.283903 12.4186 0.496239 11.5814L4.68064 7.46512C4.68064 7.46512 4.68064 7.46512 4.68064 7.39535L0.709005 3.48837Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3116_1579">
                      <rect width="10" height="15" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <div
                aria-hidden="true"
                id="start-date-container"
                style={{ position: "absolute", right: "10px" }}
                className="flex-j-start flex-container flex-al-center"
              >
                <div
                  style={{
                    position: "relative",
                    left: "40px",
                    zIndex: 1,
                    top: "5px",
                  }}
                >
                  <CalendarIcon width="22px" height="22px" color="#519acc" />
                </div>
                <SingleDatePicker
                  date={this.state.pickerDate}
                  onDateChange={this.onDateChangePicker}
                  focused={this.state.pickerFocus}
                  onFocusChange={this.onFocusChangePicker}
                  numberOfMonths={1}
                  placeholder={"Select a date"}
                  orientation={"horizontal"}
                  daySize={30}
                  navPrev={
                    <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                      <RoundDropdown rotate={"rotate(90deg)"} />
                    </div>
                  }
                  navNext={
                    <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                      <RoundDropdown rotate={"rotate(-90deg)"} />
                    </div>
                  }
                  hideKeyboardShortcutsPanel
                  inputIconPosition={"after"}
                  customInputIcon={<RoundDropdown />}
                  isOutsideRange={this.isDayBlocked}
                  id="single_date_picker"
                />
              </div>
            </div>
            <ScheduleDateHeaders
              userSchedule={true}
              userCount={1}
              twoWeeks={this.state.twoWeeks}
              currentWeekStart={this.state.currentWeekStart}
            />
          </div>
        </div>
        {this.renderTasks()}

        <ReactModal
          refreshUserTasks={this.getUserTasks}
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
        />
      </div>
    );
  };

  handleProfilePicChange = (e) => {
    var file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState(
        {
          userImage: e.target.result,
          imageFile: file,
          updated: true,
        },
        () => {
          if (this.state.updated && !window.onbeforeunload) {
            window.onbeforeunload = this.beforeUnload;
          }
        }
      );
    };
    reader.readAsDataURL(file);
  };

  setCurrentView = (currentView) => {
    this.setState({ currentView });
  };

  handleInputChange = (e) => {
    var name = e.target.name;
    this.setState(
      {
        [name]: e.target.value,
        updated: true,
      },
      () => {
        if (this.state.updated && !window.onbeforeunload) {
          window.onbeforeunload = this.beforeUnload;
        }
      }
    );
  };

  validateEmail(email) {
    if (
      email &&
      email.includes("@") &&
      email.includes(".") &&
      email.indexOf("@") !== 0 &&
      email.split("@").length === 2 &&
      email.split("@")[1].split(".").length === 2 &&
      email.split("@")[1].split(".")[0].length > 0 &&
      email.split("@")[1].split(".")[1].length > 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  getCurrentView = () => {
    switch (this.state.currentView) {
      case 0:
        return (
          <UserForm
            handleSubmit={this.handleSubmit}
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            handleProfilePicChange={this.handleProfilePicChange}
            setRoleId={this.setRoleId}
            setFullTime={this.setFullTime}
            setContractor={this.setContractor}
            setWorkLocation={this.setWorkLocation}
            setBookingConfirm={this.setBookingConfirm}
            handleAdditionPermission={this.handleAdditionPermission}
            deleteUser={this.deleteUser}
            state={this.state}
            accountView={false}
            currentUserRoleId={this.props.currentUserRoleId}
          />
        );
      case 1:
        return (
          <div style={{ padding: "32px" }}>
            <p
              style={{
                margin: "0px 0px 21px",
                fontFamily: "Manrope",
                fontWeight: "600",
                fontSize: "16px",
              }}
            >
              {this.state.userName}'s Assigned Projects
            </p>
            <Table
              data={this.props.projects}
              headers={[
                { field: "name", label: "Project Name" },
                { field: "client_name", label: "Client Name" },
                { field: "tag_name", label: "Type" },
                { field: "active", label: "Status" },
              ]}
            />
          </div>
        );
      case 2:
        return <>{this.userSchedule()}</>;
    }
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: "#f2f2f2",
          overflowX: "auto",
          padding: "16px",
          minHeight: "calc(100vh - 68px)",
        }}
        id="user-show-page"
      >
        <div
          style={{
            height: "calc(100vh - 96px)",
            border: "1px solid #d7d7d7",
            borderRadius: "10px",
            backgroundColor: "white",
            padding: "0px",
            overflow: "hidden",
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <UserShowSidebar
            fullTime={this.state.fullTime}
            userImage={this.state.userImage}
            user={this.state.user}
            roleId={this.state.roleId}
            contractor={this.state.contractor}
            currentView={this.state.currentView}
            setCurrentView={this.setCurrentView}
            show={true}
            handleProfilePicChange={this.handleProfilePicChange}
            currentUserRole={this.props.currentUserRoleId}
          />
          <div className="user-show-section">
            <UserHeader title={"User"} />
            {this.getCurrentView()}
          </div>
        </div>
      </div>
    );
  }
}

export default UserShow;
