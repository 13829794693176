import React, { useMemo } from "react";
import styled from "styled-components";
import moment from "moment";

const ScheduleDateHeaders = ({
  filtersSelected,
  twoWeeks,
  currentWeekStart,
  userSchedule,
  userCount,
  handleHideToggle,
  hideRow,
}) => {
  const currentDay = moment();

  const week = useMemo(() => {
    const monday = moment(currentWeekStart);
    const dates = [monday.format("M/DD")];

    for (let i = 0; i < 9; i++) {
      dates.push(monday.add(1, "day").format("M/DD"));
      if (i === 3) {
        if (twoWeeks) monday.add(2, "days");
        else break;
      }
    }

    return dates;
  }, [currentWeekStart, twoWeeks]);

  return (
    <WeekContainer className="row frame-row dark-gray-text">
      <div className="col s12" id="date-week-container">
        <Column twoWeeks={twoWeeks} userSchedule={userSchedule}>
          {!userSchedule && (
            <>
              <p
                style={{
                  margin: "13px 0px 0px",
                  paddingLeft: "10px",
                  fontFamily: "Manrope",
                  fontWeight: "600",
                  fontSize: "13px",
                }}
              >
                {userCount} Users
              </p>
              {filtersSelected && (
                <ToggleWrapper>
                  <Switch className="switch schedule-toggle">
                    <input type="checkbox" onChange={handleHideToggle} />
                    <span className="slider round"></span>
                    <ToggleText hideRow={hideRow}>
                      {hideRow ? "ON" : "OFF"}
                    </ToggleText>
                  </Switch>
                  <ToggleLabel>Active Bookings</ToggleLabel>
                </ToggleWrapper>
              )}
            </>
          )}
        </Column>

        {week.map((d, i) => {
          const currentDate = moment().day(i > 4 ? i - 4 : i + 1);
          const formattedDate = currentDate.format(
            twoWeeks ? "ddd M/DD" : "dddd M/DD"
          );

          return (
            <StyledDateHeader
              key={d}
              twoWeeks={twoWeeks}
              today={week.indexOf(currentDay.format("M/DD")) === i}
              userSchedule={userSchedule}
              aria-label={formattedDate}
            >
              <p>{currentDate.format(twoWeeks ? "ddd" : "dddd")}</p>
              <p>{d}</p>
            </StyledDateHeader>
          );
        })}
      </div>
    </WeekContainer>
  );
};

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding-left: 10px;
`;
const ToggleLabel = styled.span`
  font-family: Arial;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  margin-left: 8px;
  white-space: nowrap;
`;
const StyledDateHeader = styled.div`
  width: ${(props) =>
    props.twoWeeks
      ? props.userSchedule
        ? "calc(10% - 15px)"
        : "9.09%"
      : props.userSchedule
      ? "calc(20% - 30px)"
      : "16.66%"};
  display: flex;
  height: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.today &&
    `background-color: rgba(211, 211, 211, 0.678);
     border-bottom: solid 4px #519acc;
     padding-top: 4px;`}

  > p:first-child {
    margin-bottom: 0;
    font-family: Manrope;
    font-size: 13px;
    font-weight: 600;
  }
  > p:last-child {
    margin-top: 0;
    font-family: Arial;
    font-size: 13px;
    font-weight: 400;
  }
`;
const ToggleText = styled.span`
  position: absolute;
  bottom: 20px;
  left: ${(props) => (props.hideRow ? "18px" : "38px")};
  font-family: Arial;
  font-size: 9px;
  font-weight: 400;
  color: #fff;
`;
const WeekContainer = styled.div`
  padding: 0;
  background-color: white;
`;

const Column = styled.div`
  width: ${(props) =>
    props.userSchedule ? "150px" : props.twoWeeks ? "9.09%" : "16.66%"};
`;

const Switch = styled.label`
  display: inline-block;

  > input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  > input:checked + .slider {
    background-color: #2196f3;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .slider:before {
    margin-top: 0px !important;
    position: absolute;
    content: "";
    height: 26px !important;
    width: 26px !important;
    bottom: 0px !important;
    background-color: white;
    top: 0px !important;
    left: 0px !important;
    border-top: 1px solid #519acc !important;
    border-left: 1px solid #519acc !important;
    border-right: 1px solid #519acc !important;
    border-bottom: 1px solid #519acc !important;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    width: 52px;
    background-color: #717171;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &.round {
      border-radius: 34px;
    }
    &.round:before {
      border-radius: 50%;
    }
  }
`;

export default ScheduleDateHeaders;
