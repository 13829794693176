import React from "react";
import axios from "axios";
import moment from "moment";
import ModalHeadline from "components/modals/ModalHeadline";
import CompleteTransition from "../projects/transitions/complete_transition";
import ConfirmedTransition from "../projects/transitions/confirmed_transition";
import EstRequestTransition from "../projects/transitions/est_request_transition";
import EstSentTransition from "../projects/transitions/est_sent_transition";
import LeadTransition from "../projects/transitions/lead_transition";
import ReadyingTransition from "../projects/transitions/readying_transition";

class TaskTransitionModal extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      comments: data.project.comments,
      submitted: false,
      project: null,
      loading: true,
    };
    this.submitData = this.submitData.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.commentSubmit = this.commentSubmit.bind(this);
    this.commentHandler = this.commentHandler.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.updateProjectStatus = this.updateProjectStatus.bind(this);
  }

  componentDidMount() {
    this.fetchLatestProjectData();
    const elem = document.getElementById("transition-task-modal");
    if (elem) {
      const height = elem.scrollHeight;
      if (height <= 500) {
        elem.style.overflow = "visible";
      }
    }
    window.addEventListener("beforeunload", this.updateProjectStatus);
    window.addEventListener("popstate", this.updateProjectStatus);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.updateProjectStatus);
    window.removeEventListener("popstate", this.updateProjectStatus);
  }

  commentHandler(action, comment) {
    const { comments } = this.state;
    const newComments = [...comments];
    if (action === "create") {
      newComments.push(comment);
    } else if (action === "delete" || action === "edit") {
      for (let i = 0; i < newComments.length; i += 1) {
        if (newComments[i].id === comment.id) {
          if (action === "delete") {
            newComments.splice(i, 1);
          } else if (action === "edit") {
            newComments.splice(i, 1, comment);
          }
          break;
        }
      }
    }
    this.setState({ comments: newComments });
  }

  commentSubmit(e) {
    e.preventDefault();
    const { commentInput } = this.state;
    const { data } = this.props;
    const { project, currentUser } = data;
    axios
      .post(`/comments.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        comment: {
          text: commentInput,
          user_id: currentUser.id,
          project_id: project.id,
        },
      })
      .then((res) => {
        this.setState({ commentInput: "" });
        this.commentHandler("create", res.data);
        // eslint-disable-next-line no-undef
        M.toast({
          html: `Comment created`,
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch((err) => console.error(err));
  }
  fetchLatestProjectData() {
    const { data } = this.props;
    const { project } = data;

    axios
      .get(`/projects/${project.id}/latest_data`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState({ project: res.data, loading: false });
      })
      .catch((err) => {
        M.toast({ html: "Failed to fetch project data", classes: "red" });
        this.setState({ loading: false });
      });
  }

  getStage() {
    const { setBackdropLoader, data } = this.props;
    const { currentUser, transition, leadOptions, userOptions } = data;
    const { project } = this.state;

    switch (transition) {
      case "lead_transition":
        return (
          <LeadTransition
            cancelHandler={this.updateProjectStatus}
            currentUser={currentUser}
            leadOptions={leadOptions}
            project={project}
            submitHandler={this.submitData}
          />
        );
      case "requesting_transition":
        return (
          <EstRequestTransition
            cancelHandler={this.updateProjectStatus}
            closeModal={this.closeModal}
            currentUser={currentUser}
            leadOptions={leadOptions}
            project={project}
            submitHandler={this.submitData}
          />
        );
      case "sending_transition":
        return (
          <EstSentTransition
            cancelHandler={this.updateProjectStatus}
            currentUser={currentUser}
            leadOptions={leadOptions}
            project={project}
            submitHandler={this.submitData}
          />
        );
      case "confirming_transition":
        return (
          <ConfirmedTransition
            cancelHandler={this.updateProjectStatus}
            currentUser={currentUser}
            project={project}
            submitHandler={this.submitData}
            userOptions={userOptions}
          />
        );
      case "complete_transition":
        return (
          <CompleteTransition
            cancelHandler={this.updateProjectStatus}
            currentUser={currentUser}
            project={project}
            setBackdropLoader={setBackdropLoader}
            submitHandler={this.submitData}
          />
        );
      case "readying_transition":
        return (
          <ReadyingTransition
            cancelHandler={this.updateProjectStatus}
            currentUser={currentUser}
            project={project}
            submitInvoice={this.submitInvoice}
            submitHandler={this.submitData}
          />
        );
      case "readying_back_transition":
        return (
          <CompleteTransition
            cancelHandler={this.updateProjectStatus}
            currentUser={currentUser}
            project={project}
            setBackdropLoader={setBackdropLoader}
            submitHandler={this.submitData}
          />
        );
      default:
        return null;
    }
  }

  closeModal() {
    const { modalAction } = this.props;
    modalAction(false, "", {});
  }

  getPreviousState() {
    const { data } = this.props;
    const { transition } = data;
    const stateMap = {
      lead_transition: "leading",
      requesting_transition: "est_requesting",
      sending_transition: "est_sending",
      confirming_transition: "project_confirming",
      complete_transition: "completing",
      readying_transition: "readying",
      readying_back_transition: "invoicing",
    };
    return stateMap[transition];
  }

  getNextState() {
    const { data } = this.props;
    const stateMap = {
      lead_transition: "est_requesting",
      requesting_transition: "est_sending",
      sending_transition: "project_confirming",
      confirming_transition: "testing",
      complete_transition: "readying",
      readying_transition: "invoicing",
      readying_back_transition: "readying",
    };
    return stateMap[data.transition];
  }

  updateProjectStatus() {
    const { submitted } = this.state;
    const { data } = this.props;
    const { project, projectHandler } = data;
    if (!submitted) {
      const prev = this.getPreviousState();
      axios
        .patch(`project_statuses/${project.project_status.id}.json`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          project_status: {
            aasm_state: prev,
          },
        })
        .then(() => {
          // eslint-disable-next-line no-undef
          M.toast({
            html: "Project Move Cancelled",
            displayLength: 3000,
            classes: "orange",
          });
          project.project_status.aasm_state = prev;
          projectHandler(prev, prev, project);
          this.closeModal();
        })
        .catch((err) => {
          // eslint-disable-next-line no-undef
          M.toast({
            html: err.response.data,
            displayLength: 3000,
            classes: "red",
          });
          console.error(err);
        });
    }
  }

  handleInputChange(e) {
    const { name } = e.target;
    this.setState({ [name]: e.target.value });
  }

  submitInvoice = (id, inv_number) => {
    const { data } = this.props;
    const { projectHandler } = data;
    const prev = this.getPreviousState();
    const next = this.getNextState();
    axios
      .patch(`/invoice_details/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        invoice: {
          inv_number,
          submitted_date: moment().format("YYYY-MM-DD"),
        },
      })
      .then((res) => {
        projectHandler(prev, next, res.data);
        this.closeModal();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  submitData(update) {
    const { data } = this.props;
    const { project, projectHandler } = data;
    const prev = this.getPreviousState();
    const next = this.getNextState();

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    const projectData = {
      project: update,
    };

    axios
      .patch(`/projects/${project.id}.json`, projectData, {
        headers,
      })
      .then((res) => {
        // eslint-disable-next-line no-undef
        M.toast({ html: "Submitted", classes: "green" });
        projectHandler(prev, next, res.data);
        this.closeModal();
      })
      .catch((err) => {
        // eslint-disable-next-line no-undef
        M.toast({ html: err.response.data, classes: "red" });
        console.error(err);
      });
  }

  render() {
    const { data } = this.props;
    const { transition } = data;
    const { loading } = this.state;

    if (loading) {
      return (
        <div id="transition-task-modal" className="modal-content">
          <div className="loading">Loading...</div>
        </div>
      );
    }

    return (
      <div id="transition-task-modal" className="modal-content">
        <ModalHeadline
          modalKey={transition}
          closeModal={this.updateProjectStatus}
          subHeading={data.project.name}
        />
        {this.getStage()}
      </div>
    );
  }
}

export default TaskTransitionModal;
