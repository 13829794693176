import React from "react";
import moment from "moment";
import axios from "axios";
import Select from "react-select";
import NewCalenderIcon from "components/icons/new_calender_icon";
import { SingleDatePicker, DateRangePicker } from "react-dates";
import Swal from "sweetalert2";
import styled from "styled-components";
import ReactModal from "../modals/react_modal";
import "react-dates/initialize";
import SingleSelectStyles from "../common/single_select_styles";
import PageNav from "../common/page_nav";
import RoundDropdown from "../projects/round_dropdown";
import CalendarIcon from "../icons/calendar_icon";
import ScheduleDateHeaders from "./schedule_date_headers";
import FiltersControls from "./components/FiltersControls";
import TasksTable from "./components/TasksTable";
import { sentryInit } from "../utils";

sentryInit();
class ScheduleContainer extends React.Component {
  constructor(props) {
    super(props);

    const url = new URL(window.location.href);
    this.getDataAgain = false;
    if (url.searchParams.toString()) {
      localStorage.setItem("schedule_url_params", url.searchParams.toString());
    } else if (localStorage.getItem("schedule_url_params")) {
      const params = localStorage.getItem("schedule_url_params").split("&");
      params.forEach((p) => {
        const param = p.split("=");
        if (
          (param[0] === "end_date" &&
            param[1] !== moment().endOf("week").format("YYYY-MM-DD")) ||
          (param[0] === "start_date" &&
            param[1] !==
              moment().startOf("week").add(1, "d").format("YYYY-MM-DD")) ||
          (param[0] === "two_weeks" && param[1] === "true")
        ) {
          this.getDataAgain = true;
        }
        url.searchParams.set(param[0], param[1].replace(/%2C/g, ","));
      });
      history.replaceState(
        window.history.state,
        null,
        `?${url.searchParams.toString()}`
      );
    }
    const twoWeeks = url.searchParams.get("two_weeks") === "true";
    const start = moment().startOf("week").add(1, "d").format("YYYY-MM-DD");
    const end = moment(start)
      .endOf("week")
      .add(twoWeeks ? 7 : 0, "days")
      .format("YYYY-MM-DD");
    const availability = url.searchParams.get("availability") === "true";
    let filter = url.searchParams.get("filter");
    filter = filter && filter !== "false" ? JSON.parse(filter) : "";
    const currentWeekStart = url.searchParams.get("start_date")
      ? url.searchParams.get("start_date")
      : start;
    const currentWeekEnd = url.searchParams.get("end_date")
      ? url.searchParams.get("end_date")
      : end;
    this.state = {
      currentWeekStart,
      currentWeekEnd,
      hoursWorkedStartDate: moment(currentWeekStart),
      hoursWorkedEndDate: moment(currentWeekEnd),
      hoursWorkedInputFocus: null,
      currentFilter: filter,
      currentUserFilters: this.props.currentUserFilters,
      users: this.props.users,
      closures: this.props.closures,
      value: "",
      loading: false,
      fullTime: null,
      availability: availability
        ? { label: "Availability", value: true }
        : { label: "All Tasks", value: false },
      twoWeeks,
      modalOpen: false,
      searchData: this.props.users,
      search: "",
      teamFilters: this.props.teamFilters,
      selectedFilter: url.searchParams.get("selectedFilter")
        ? url.searchParams
            .get("selectedFilter")
            .split(",")
            .map((x) => parseInt(x))
        : [],
      selectedStatus: url.searchParams.get("selectedStatus")
        ? url.searchParams
            .get("selectedStatus")
            .split(",")
            .map((x) => parseInt(x))
        : [],
      selectedProject: url.searchParams.get("selectedProject")
        ? url.searchParams
            .get("selectedProject")
            .split(",")
            .map((x) => parseInt(x))
        : [],
      selectedClient: url.searchParams.get("selectedClient")
        ? url.searchParams
            .get("selectedClient")
            .split(",")
            .map((x) => parseInt(x))
        : [],
      selectedTeam: url.searchParams.get("selectedTeam")
        ? url.searchParams
            .get("selectedTeam")
            .split(",")
            .map((x) => parseInt(x))
        : [],
      currentModal: "",
      modalData: {},
      hoursBooked: 0,
      pickerFocus: false,
      // pickerDate: moment(new Date().toISOString()),
      pickerDate: null,
      dynamicListResizer: Math.random(),
    };

    this.updateDate = this.updateDate.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.setModal = this.setModal.bind(this);
    this.refreshFilters = this.refreshFilters.bind(this);
    this.newFilterClick = this.newFilterClick.bind(this);
    this.weekSwitchClick = this.weekSwitchClick.bind(this);
    this.onDateChangePicker = this.onDateChangePicker.bind(this);
    this.onFocusChangePicker = this.onFocusChangePicker.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.prevWeek = this.prevWeek.bind(this);
    this.upcomingWeek = this.upcomingWeek.bind(this);
    this.resetWeek = this.resetWeek.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.editFilterClick = this.editFilterClick.bind(this);
    this.dynamicListRef = React.createRef();
    this.draggingItem = null;
  }

  componentDidMount() {
    axios.defaults.headers.common = {
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    // document.querySelector(".react-preload").style.display = "none";
    this.setUpCable();
    const url = new URL(location.href);
    if (this.getDataAgain) {
      this.setTwoWeeks(this.state.twoWeeks, true);
    }
    if (url.searchParams.toString()) {
      this.setDigestedData("");
    }
    document.title = "Syncit - Schedule";
  }

  setDraggingItem = (task) => {
    this.draggingItem = task;
  };

  getDraggingItem = () => {
    return this.draggingItem;
  };

  setUpCable = () => {
    this.tasksChannel = App.cable.subscriptions.create(
      { channel: "TasksChannel" },
      {
        received: (data) => {
          const start = this.state.currentWeekStart;
          const end = this.state.currentWeekEnd;
          const tasks = data.tasks.filter(
            (t) => t.start_date >= start && t.end_date <= end
          );
          if (tasks.length > 0) {
            switch (data.action) {
              case "update":
                this.setState(
                  (prevState) => ({
                    users: prevState.users.map((user) => {
                      if (tasks[0].user_id === user.id) {
                        const taskIds = tasks.map((t) => t.id);
                        return {
                          ...user,
                          ...{
                            tasks: user.tasks.map((t) =>
                              taskIds.indexOf(t.id) !== -1
                                ? tasks[taskIds.indexOf(t.id)]
                                : t
                            ),
                          },
                        };
                      }
                      return user;
                    }),
                    dynamicListResizer: Math.random(),
                  }),
                  () => {
                    if (
                      this.state.selectedFilter.length +
                        this.state.selectedProject.length +
                        this.state.selectedClient.length +
                        this.state.selectedStatus.length +
                        this.state.selectedTeam.length >=
                        1 ||
                      this.state.availability.value !== false ||
                      this.state.search != ""
                    ) {
                      this.setDigestedData(this.state.search);
                    }
                  }
                );
                break;
              case "create":
                this.setState(
                  (prevState) => ({
                    users: prevState.users.map((user) =>
                      tasks[0].user_id === user.id &&
                      !user.tasks.map((t) => t.id).includes(tasks[0].id)
                        ? { ...user, ...{ tasks: [...user.tasks, ...tasks] } }
                        : user
                    ),
                    dynamicListResizer: Math.random(),
                  }),
                  () => {
                    if (
                      this.state.selectedFilter.length +
                        this.state.selectedProject.length +
                        this.state.selectedClient.length +
                        this.state.selectedStatus.length +
                        this.state.selectedTeam.length >=
                        1 ||
                      this.state.availability.value !== false ||
                      this.state.search != ""
                    ) {
                      this.setDigestedData(this.state.search);
                    }
                  }
                );
                break;
              case "delete":
                this.setState(
                  (prevState) => ({
                    users: prevState.users.map((user) => {
                      if (tasks[0].user_id === user.id) {
                        const taskIds = tasks.map((t) => t.id);
                        return {
                          ...user,
                          ...{
                            tasks: user.tasks.filter(
                              (t) => taskIds.indexOf(t.id) === -1
                            ),
                          },
                        };
                      }
                      return user;
                    }),
                    dynamicListResizer: Math.random(),
                  }),
                  () => {
                    if (
                      this.state.selectedFilter.length +
                        this.state.selectedProject.length +
                        this.state.selectedClient.length +
                        this.state.selectedStatus.length +
                        this.state.selectedTeam.length >=
                        1 ||
                      this.state.availability.value !== false ||
                      this.state.search != ""
                    ) {
                      this.setDigestedData(this.state.search);
                    }
                  }
                );
                break;
              case "task_moved":
                this.setState(
                  (prevState) => ({
                    users: prevState.users.map((user) => {
                      if (
                        data.original_user_id === user.id &&
                        tasks[0].user_id === user.id
                      ) {
                        return {
                          ...user,
                          ...{
                            tasks: user.tasks.map((t) => {
                              const taskFind = tasks.find(
                                (t2) => t2.id === t.id
                              );
                              if (taskFind) {
                                return taskFind;
                              }
                              return t;
                            }),
                          },
                        };
                      }
                      if (data.original_user_id === user.id) {
                        return {
                          ...user,
                          ...{
                            tasks: user.tasks.filter(
                              (t) => tasks[0].id !== t.id
                            ),
                          },
                        };
                      }
                      if (tasks[0].user_id === user.id) {
                        const task_ids = tasks.map((t) => t.id);
                        return {
                          ...user,
                          ...{
                            tasks: [
                              ...user.tasks.filter(
                                (t) => task_ids.indexOf(t.id) === -1
                              ),
                              ...tasks,
                            ],
                          },
                        };
                      }
                      return user;
                    }),
                    dynamicListResizer: Math.random(),
                  }),
                  () => {
                    if (
                      this.state.selectedFilter.length +
                        this.state.selectedProject.length +
                        this.state.selectedClient.length +
                        this.state.selectedStatus.length +
                        this.state.selectedTeam.length >=
                        1 ||
                      this.state.availability.value !== false ||
                      this.state.search != ""
                    ) {
                      this.setDigestedData(this.state.search);
                    }
                  }
                );
                break;
              case "task_copied":
                this.setState(
                  (prevState) => ({
                    users: prevState.users.map((user) => {
                      if (tasks[0].user_id === user.id) {
                        return {
                          ...user,
                          ...{ tasks: [...user.tasks, ...tasks] },
                        };
                      }
                      return user;
                    }),
                    dynamicListResizer: Math.random(),
                  }),
                  () => {
                    if (
                      this.state.selectedFilter.length +
                        this.state.selectedProject.length +
                        this.state.selectedClient.length +
                        this.state.selectedStatus.length +
                        this.state.selectedTeam.length >=
                        1 ||
                      this.state.availability.value !== false ||
                      this.state.search != ""
                    ) {
                      this.setDigestedData(this.state.search);
                    }
                  }
                );
                break;
            }
          }
        },
      }
    );
  };

  updateDate(start_date, end_date) {
    axios
      .get(`/api/v1/tasks/master.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          start_date,
          end_date,
        },
      })
      .then((res) => {
        this.setState(
          {
            currentWeekStart: start_date,
            currentWeekEnd: end_date,
            hoursWorkedStartDate: moment(start_date),
            hoursWorkedEndDate: moment(end_date),
            users: res.data.users,
            loading: false,
            closures: res.data.closures,
            dynamicListResizer: Math.random(),
          },
          () => {
            if (
              this.state.selectedFilter.length +
                this.state.selectedProject.length +
                this.state.selectedClient.length +
                this.state.selectedStatus.length +
                this.state.selectedTeam.length >=
                1 ||
              this.state.availability.value !== false ||
              this.state.search != ""
            ) {
              this.setDigestedData(this.state.search);
            }
          }
        );
      })
      .catch((err) => console.error(err));
  }

  setModal(bool, page, data) {
    this.setState({
      modalOpen: bool,
      currentModal: page,
      modalData: data,
    });
  }

  handleDateChange(date) {
    const weekStart = moment(date)
      .startOf("week")
      .add(1, "day")
      .format("YYYY-MM-DD");
    const weekEnd = this.state.twoWeeks
      ? moment(date).endOf("week").add(7, "day").format("YYYY-MM-DD")
      : moment(date).endOf("week").format("YYYY-MM-DD");
    this.setState({ loading: true });
    setTimeout(() => {
      this.updateDate(weekStart, weekEnd);
    }, 500);
  }

  refreshFilters(type) {
    let url;
    let name;
    if (type === "user") {
      url = "api/v1/filters";
      name = "currentUserFilters";
    } else {
      url = "api/v1/team_filters";
      name = "teamFilters";
    }
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState({
          [name]: res.data,
        });
      })
      .catch((err) => console.error(err));
  }

  dragDropAddTask = async (data, newData, copy) => {
    if (data) {
      var task = JSON.parse(data);
    }
    let cancel = false;
    let partTimeOverConfirmed = true;
    let totalHours;
    if (
      newData.userFullTime === false &&
      (task.userId != newData.userId || copy)
    ) {
      await axios
        .get(
          `/part_time_limit_check?date=${newData.date}&task_id=${task.taskId}&user_id=${newData.userId}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          if (res.data.over_limit) {
            totalHours = res.data.total_hours;
            if (
              res.data.existing_request &&
              this.props.currentUser.role_id < 4
            ) {
              await Swal.fire({
                title: `Existing Part-Time Limit Request`,
                text: "This user already has a pending request for extra hours this week. That request will need to be approved or closed before more hours can be requested.",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Okay",
              });
              cancel = true;
            } else {
              await Swal.fire({
                title: `Part-Time Hours Limit`,
                text:
                  this.props.currentUser.role_id > 3
                    ? "This booking will exceed part-time employee hour limit. Would you like to proceed?"
                    : `This booking will exceed part-time employee hour limit and needs to be approved by an Admin or HR. Would you like to proceed?`,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
              }).then((result) => {
                if (result.value) {
                  if (this.props.currentUser.role_id < 4) {
                    partTimeOverConfirmed = false;
                  }
                } else {
                  cancel = true;
                }
              });
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    if (cancel) {
      return;
    }
    axios
      .patch(
        `/api/v1/tasks/task_drag/${task.taskId}${
          totalHours ? `?total_hours=${totalHours}` : ""
        }`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          task: {
            start_date: moment(newData.date),
            end_date: moment(newData.date),
            order: newData.order,
            user_id: newData.userId,
            task_group_id: null,
            reorder_ids: newData.reorder_ids,
            reorder_direction: newData.reorder_direction,
            part_time_over_confirmed: partTimeOverConfirmed,
            copy,
          },
        }
      )
      .then((res) => {
        M.toast({
          html: `Booking ${copy ? "copied" : "moved"}`,
          displayLength: 3000,
          classes: "green",
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  renderDateDisplay() {
    const displayDateStart = moment(this.state.currentWeekStart).format(
      "MM/DD/YYYY"
    );
    const displayDateEnd = moment(this.state.currentWeekEnd)
      .subtract(1, "day")
      .format("MM/DD/YYYY");
    return (
      <>
        {this.state.loading ? (
          <div
            style={{ height: "30px", width: "30px" }}
            className="page-load-spinner"
          />
        ) : (
          <>
            <button
              style={{
                height: "15px",
                background: "none",
                border: "none",
                cursor: "pointer",
                padding: "0px",
                marginRight: "16px",
                position: "relative",
              }}
              id="prevWeek"
              onClick={this.prevWeek}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="15"
                viewBox="0 0 10 15"
                fill="none"
                style={{ verticalAlign: "-3px" }}
              >
                <g clipPath="url(#clip0_2850_1302)">
                  <path
                    d="M9.29099 11.5116C10.0711 12.2791 10.213 13.5349 9.43284 14.3023C9.00731 14.7209 8.43993 15 7.94348 15C7.44702 15 6.87965 14.7907 6.52504 14.4419L0.354823 8.37209C-0.141631 7.88372 -0.141631 7.18605 0.354823 6.69768L2.41156 4.67442L6.59596 0.55814C7.3761 -0.209302 8.6527 -0.209302 9.50376 0.55814C10.2839 1.32558 10.2839 2.5814 9.50376 3.4186L5.31936 7.53488C5.31936 7.53488 5.31936 7.53488 5.31936 7.60465L9.29099 11.5116Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2850_1302">
                    <rect
                      width="10"
                      height="15"
                      fill="white"
                      transform="translate(10 15) rotate(180)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
            <span
              style={{
                fontSize: "22px",
                color: "#fff",
                fontFamily: "Manrope",
                fontWeight: "700",
                height: "0px",
                position: "relative",
                top: "-16px",
              }}
            >
              {displayDateStart} - {displayDateEnd}
            </span>
            <button
              style={{
                height: "15px",
                background: "none",
                border: "none",
                cursor: "pointer",
                padding: "0px",
                marginLeft: "16px",
                position: "relative",
              }}
              id="upcomingWeek"
              onClick={this.upcomingWeek}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="15"
                viewBox="0 0 10 15"
                fill="none"
                style={{ verticalAlign: "-3px" }}
              >
                <g clipPath="url(#clip0_2850_1305)">
                  <path
                    d="M0.709005 3.48837C-0.0711367 2.72093 -0.212981 1.46512 0.567161 0.697674C0.992693 0.27907 1.56007 0 2.05652 0C2.55298 0 3.12035 0.209302 3.47496 0.55814L9.64518 6.62791C10.1416 7.11628 10.1416 7.81395 9.64518 8.30233L7.58844 10.3256L3.40404 14.4419C2.6239 15.2093 1.3473 15.2093 0.496239 14.4419C-0.283903 13.6744 -0.283903 12.4186 0.496239 11.5814L4.68064 7.46512C4.68064 7.46512 4.68064 7.46512 4.68064 7.39535L0.709005 3.48837Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2850_1305">
                    <rect width="10" height="15" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </>
        )}
      </>
    );
  }

  prevWeek() {
    this.setState({ loading: true });
    // this.dynamicListRef.current.props.height = "420"
    const newWeekStart = moment(this.state.currentWeekStart.slice())
      .subtract(this.state.twoWeeks ? 14 : 7, "d")
      .format("YYYY-MM-DD");
    const newWeekEnd = moment(this.state.currentWeekEnd.slice())
      .subtract(this.state.twoWeeks ? 14 : 7, "d")
      .format("YYYY-MM-DD");
    setTimeout(() => {
      this.updateDate(newWeekStart, newWeekEnd);
    }, 500);
    const url = new URL(window.location.href);
    const params = url.searchParams;
    params.set("start_date", newWeekStart);
    params.set("end_date", newWeekEnd);
    localStorage.setItem("schedule_url_params", params.toString());
    history.replaceState(window.history.state, null, `?${params.toString()}`);
    Cookies.set("master_week_start", newWeekStart, { path: "/" });
  }

  upcomingWeek() {
    this.setState({ loading: true });
    const newWeekStart = moment(this.state.currentWeekStart.slice())
      .add(this.state.twoWeeks ? 14 : 7, "d")
      .format("YYYY-MM-DD");
    const newWeekEnd = moment(this.state.currentWeekEnd.slice())
      .add(this.state.twoWeeks ? 14 : 7, "d")
      .format("YYYY-MM-DD");
    setTimeout(() => {
      this.updateDate(newWeekStart, newWeekEnd);
    }, 500);
    const url = new URL(window.location.href);
    const params = url.searchParams;
    params.set("start_date", newWeekStart);
    params.set("end_date", newWeekEnd);
    localStorage.setItem("schedule_url_params", params.toString());
    history.replaceState(window.history.state, null, `?${params.toString()}`);
    Cookies.set("master_week_start", newWeekStart, { path: "/" });
  }

  resetWeek() {
    this.setState({ loading: true });
    const newWeekStart = moment()
      .startOf("week")
      .add(1, "d")
      .format("YYYY-MM-DD");
    const newWeekEnd = this.state.twoWeeks
      ? moment().endOf("week").add(7, "day").format("YYYY-MM-DD")
      : moment().endOf("week").format("YYYY-MM-DD");
    setTimeout(() => {
      this.updateDate(newWeekStart, newWeekEnd);
    }, 500);
    const url = new URL(window.location.href);
    const params = url.searchParams;
    params.set("start_date", newWeekStart);
    params.set("end_date", newWeekEnd);
    localStorage.setItem("schedule_url_params", params.toString());
    history.replaceState(window.history.state, null, `?${params.toString()}`);
    Cookies.set("master_week_start", newWeekStart, { path: "/" });
  }

  setTwoWeeks(twoWeeks, skipDateChange) {
    const end_date = skipDateChange
      ? this.state.currentWeekEnd
      : twoWeeks
      ? moment(this.state.currentWeekEnd).add(7, "day").format("YYYY-MM-DD")
      : moment(this.state.currentWeekEnd)
          .subtract(7, "day")
          .format("YYYY-MM-DD");
    this.setState({ currentWeekEnd: end_date, loading: true });
    axios
      .get(`api/v1/tasks/master.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          start_date: this.state.currentWeekStart,
          end_date,
        },
      })
      .then((res) => {
        this.setState(
          {
            users: res.data.users,
            loading: false,
            closures: res.data.closures,
            twoWeeks,
            dynamicListResizer: Math.random(),
          },
          () => {
            if (
              this.state.selectedFilter.length +
                this.state.selectedProject.length +
                this.state.selectedStatus.length +
                this.state.selectedTeam.length >=
                1 ||
              this.state.availability.value !== false ||
              this.state.search != ""
            ) {
              this.setDigestedData(this.state.search);
            }
          }
        );
        const url = new URL(window.location.href);
        const params = url.searchParams;
        params.set("two_weeks", twoWeeks);
        params.set("start_date", this.state.currentWeekStart);
        params.set("end_date", end_date);
        localStorage.setItem("schedule_url_params", params.toString());
        history.replaceState(
          window.history.state,
          null,
          `?${params.toString()}`
        );
      })
      .catch((err) => console.error(err));
  }

  newFilterClick() {
    this.setModal(true, "add-filter", {
      closeButton: true,
      customClass: "sm-modal team-filter-modal",
      currentUserId: this.props.currentUser.id,
    });
  }

  editFilterClick(e) {
    let filter;
    let type;
    if (e.target.dataset.filterType === "team") {
      filter = this.state.teamFilters.find(
        (x) => x.id === parseInt(e.target.dataset.filterId)
      );
      type = "team";
    } else {
      filter = this.state.currentUserFilters.find(
        (x) => x.id === parseInt(e.target.dataset.filterId)
      );
      type = "user";
    }
    this.setModal(true, "add-filter", {
      closeButton: true,
      filter,
      handler: this.refreshFilters,
      type,
      edit: true,
      customClass: "sm-modal team-filter-modal",
      currentUserId: this.props.currentUser.id,
    });
  }

  addClosure = () => {
    this.setModal(true, "add-closure", {
      closeButton: true,
      customClass: "closures-modal",
      currentUserId: this.props.currentUser.id,
      updateClosures: this.updateClosures,
    });
  };

  filterUpdateHandler(name, filters) {
    this.setState({ [name]: filters });
  }

  updateSearch = (event) => {
    const input = event.target.value;
    this.setDigestedData(input);
  };

  searchHandler = (users, input) => {
    const { currentWeekStart, currentWeekEnd, hideRow, selectedProject } =
      this.state;

    let data = users;

    if (input && input.length > 0) {
      const searchTerm = input.toLowerCase();
      data = users.filter((user) =>
        user.name.toLowerCase().includes(searchTerm)
      );
    }

    if (hideRow) {
      data = data.filter((user) => {
        const hasCurrentWeekBookingForProject = user.tasks.some((task) => {
          const taskStartDate = new Date(task.start_date);
          const taskEndDate = new Date(task.end_date);

          return (
            taskStartDate >= new Date(currentWeekStart) &&
            taskEndDate <= new Date(currentWeekEnd) &&
            selectedProject.includes(task.project_id)
          );
        });

        return hasCurrentWeekBookingForProject;
      });
    }

    return data;
  };

  setDigestedData = (input) => {
    const filtered = this.filterHandler();
    const searched = this.searchHandler(filtered, input);
    this.setState(
      {
        search: input,
        searchData: searched,
      },
      () => {
        // Fix for row heights being weird when changing week with search or filter active
        setTimeout(() => {
          this.setState({ dynamicListResizer: Math.random() });
        }, 200);
      }
    );
  };

  handleSelectChange(e) {
    var e = e;
    const { name } = e.target;
    const value = parseInt(e.target.value);
    let old = this.state[name];
    if (old.includes(value)) {
      old = old.filter((x) => x != value);
    } else {
      old.push(value);
    }
    this.setState({ [name]: old }, () => {
      this.setDigestedData(this.state.search);
      this.filterUrlHandler(name, name, value);
    });
  }

  filterUrlChange = (key, value, append) => {
    const url = new URL(location.href);
    let newUrl;
    if (append) {
      url.searchParams.set(key, value);
      localStorage.setItem("schedule_url_params", url.searchParams.toString());
    } else {
      url.searchParams.delete(key);
      if (url.searchParams.toString()) {
        localStorage.setItem(
          "schedule_url_params",
          url.searchParams.toString()
        );
      } else {
        localStorage.removeItem("schedule_url_params");
      }
    }
    const stateObj = {};
    window.history.replaceState(window.history.state, "", url.href);
  };

  filterUrlHandler(param_key, state_key, filter_id) {
    const url = new URL(location.href);
    const paramPresent = url.searchParams.has(param_key);
    const newStatusFilter = this.state[state_key];
    let append;
    if (newStatusFilter.includes(filter_id)) {
      if (paramPresent) {
        var ids = url.searchParams.get(param_key);
        filter_id = `${ids},${filter_id.toString()}`;
      }
      append = true;
    } else if (paramPresent) {
      var ids = url.searchParams.get(param_key).split(",");
      ids = ids.filter((x) => x != filter_id.toString());
      filter_id = ids.join(",");
      if (ids.length >= 1) {
        append = true;
      } else {
        append = false;
      }
    } else {
      append = false;
    }
    this.filterUrlChange(param_key, filter_id, append);
  }

  clearSingleFilter = (name) => {
    const url = new URL(window.location.href);
    url.searchParams.delete(name);
    if (url.searchParams.toString()) {
      localStorage.setItem("schedule_url_params", url.searchParams.toString());
    } else {
      localStorage.removeItem("schedule_url_params");
    }
    window.history.replaceState(window.history.state, "", url.href);
    this.setState({ [name]: [] });
  };

  clearFilters(name) {
    const url = new URL(location.href);
    if (typeof name === "string") {
      this.setState({ [name]: [] }, () => {
        this.setDigestedData(this.state.search);
      });

      url.searchParams.delete(name);
      if (url.searchParams.toString()) {
        localStorage.setItem(
          "schedule_url_params",
          url.searchParams.toString()
        );
      } else {
        localStorage.removeItem("schedule_url_params");
      }
      window.history.replaceState(window.history.state, "", url.href);
    } else {
      url.searchParams.delete("selectedClient");
      url.searchParams.delete("selectedProject");
      url.searchParams.delete("selectedFilter");
      url.searchParams.delete("selectedStatus");
      url.searchParams.delete("selectedTeam");
      if (url.searchParams.toString()) {
        localStorage.setItem(
          "schedule_url_params",
          url.searchParams.toString()
        );
      } else {
        localStorage.removeItem("schedule_url_params");
      }
      window.history.replaceState(window.history.state, "", url.href);
      this.setState(
        {
          selectedClient: [],
          selectedProject: [],
          selectedFilter: [],
          selectedStatus: [],
          selectedTeam: [],
        },
        () => {
          this.setDigestedData(this.state.search);
        }
      );
    }
  }

  filterHandler() {
    const self = this;
    let { users } = this.state;
    const {
      selectedClient,
      selectedProject,
      selectedFilter,
      selectedStatus,
      selectedTeam,
    } = this.state;

    if (this.state.selectedClient.length >= 1) {
      users = users.filter((user) =>
        user.client_ids.some((id) => self.state.selectedClient.includes(id))
      );
    }
    if (this.state.selectedProject.length >= 1) {
      users = users.filter((user) =>
        user.project_ids.some((id) => self.state.selectedProject.includes(id))
      );
      if (this.state.selectedProject.length === 1) {
        this.getProjectBookedHours(
          this.state.hoursWorkedStartDate,
          this.state.hoursWorkedEndDate
        );
      }
    }
    if (this.state.selectedFilter.length >= 1) {
      var userIds = [];
      this.state.selectedFilter.map((filter) => {
        const inner = this.state.currentUserFilters.find(
          (x) => x.id === filter
        );
        if (inner) {
          userIds = userIds.concat(inner.user_ids);
        } else {
          userIds = this.state.selectedFilter;
        }
      });
      users = users.filter((user) => userIds.includes(user.id));
    }
    if (this.state.selectedStatus.length >= 1) {
      if (
        this.state.selectedStatus.includes(1) &&
        this.state.selectedStatus.includes(2) &&
        this.state.selectedStatus.includes(3)
      ) {
        // filter nothing all state
      } else if (
        this.state.selectedStatus.includes(1) &&
        this.state.selectedStatus.includes(2)
      ) {
        users = users.filter((user) => user.contractor === false);
      } else if (
        this.state.selectedStatus.includes(2) &&
        this.state.selectedStatus.includes(3)
      ) {
        const pt_users = users.filter((user) => user.full_time === false);
        var cons = users.filter((user) => user.contractor === true);
        users = cons.concat(pt_users);
        users = users.sort((a, b) => a.name.localeCompare(b.name));
        users = [...new Set(users)];
      } else if (
        this.state.selectedStatus.includes(1) &&
        this.state.selectedStatus.includes(3)
      ) {
        const ft_users = users.filter((user) => user.full_time === true);
        var cons = users.filter((user) => user.contractor === true);
        users = cons.concat(ft_users);
        users = users.sort((a, b) => a.name.localeCompare(b.name));
        users = [...new Set(users)];
      } else if (this.state.selectedStatus.includes(1)) {
        users = users.filter(
          (user) => user.full_time && user.contractor === false
        );
      } else if (this.state.selectedStatus.includes(2)) {
        users = users.filter(
          (user) => user.full_time === false && user.contractor === false
        );
      } else {
        users = users.filter((user) => user.contractor === true);
      }
    }
    if (this.state.selectedTeam.length >= 1) {
      var userIds = [];
      this.state.selectedTeam.map((team) => {
        const inner = this.state.teamFilters.find((x) => x.id === team);
        if (inner) {
          userIds = userIds.concat(inner.user_ids);
        }
      });
      users = users.filter((user) => userIds.includes(user.id));
    }
    if (this.state.availability.value === true) {
      users = users.filter((user) => {
        return (
          user.tasks
            .map(
              (t) =>
                t.schedule_unavailable &&
                t.project_name !== "Recurring: Unavailable"
            )
            .indexOf(true) !== -1
        );
      });
    } else if (this.state.availability.value === null) {
      users = users.filter((user) => {
        return (
          user.tasks
            .map((t) => t.created_by)
            .indexOf(this.props.currentUser.id) !== -1
        );
      });
    }
    return users;
  }

  weekSwitchClick() {
    this.setTwoWeeks(!this.state.twoWeeks);
  }

  onAvailabilityChange = (e) => {
    this.setState({ availability: e }, () => {
      this.setDigestedData(this.state.search);
    });
  };

  onDateChangePicker(date) {
    var date = moment(date).day("monday");
    if (date.isValid()) {
      this.handleDateChange(date);
      this.setState({ pickerDate: date });
    }
  }

  onFocusChangePicker({ focused }) {
    this.setState({ pickerFocus: focused }, () => {
      if (focused && this.state.twoWeeks) {
        const elem = document.querySelector(".CalendarDay__selected");
        if (elem) {
          elem.parentElement.classList.add("selected-date-row");
        }
      }
    });
  }

  isDayBlocked(day) {
    return !!(moment(day).day() === 0 || moment(day).day() === 6);
  }

  updateClosures = (closure, action) => {
    const { closures } = this.state;
    if (action === "create") {
      closures.push(closure);
      this.setState({ closures });
    } else if (action === "update") {
      var index = closures.map((c) => c.id).indexOf(closure.id);
      if (index !== -1) {
        closures.splice(index, 1, closure);
      } else {
        closures.push(closure);
      }
      this.setState({ closures });
    } else if (action === "delete") {
      var index = closures.map((c) => c.id).indexOf(closure.id);
      if (index !== -1) {
        closures.splice(index, 1);
        this.setState({ closures });
      }
    }
  };

  getProjectBookedHours = (startDate, endDate) => {
    axios
      .get(`/get_project_booked_hours/${this.state.selectedProject[0]}.json`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      })
      .then((res) => {
        this.setState({
          hoursBooked: res.data,
        });
      })
      .catch((err) => console.error(err));
  };

  handleHoursWorkedDateChange = ({ startDate, endDate }) => {
    this.setState(
      { hoursWorkedStartDate: startDate, hoursWorkedEndDate: endDate },
      () => {
        if (this.state.hoursWorkedStartDate && this.state.hoursWorkedEndDate) {
          this.getProjectBookedHours(
            this.state.hoursWorkedStartDate,
            this.state.hoursWorkedEndDate
          );
        }
      }
    );
  };

  handleHideToggle = () => {
    this.setState(
      (prevState) => ({
        hideRow: !prevState.hideRow,
      }),
      () => {
        this.setDigestedData(this.state.search);
      }
    );
  };

  render() {
    const showClear =
      this.state.selectedFilter.length +
        this.state.selectedProject.length +
        this.state.selectedClient.length +
        this.state.selectedStatus.length +
        this.state.selectedTeam.length >=
      1;
    const {
      selectedProject,
      selectedStatus,
      selectedTeam,
      selectedFilter,
      selectedClient,
    } = this.state;
    const displayDateStart = moment(this.state.currentWeekStart);
    const displayDateEnd = moment(this.state.currentWeekEnd).subtract(1, "day");
    const today = moment(new Date());
    const todayIsInRange = today.isBetween(
      displayDateStart,
      displayDateEnd,
      "days",
      "[]"
    );
    const { currentWeekStart } = this.state;
    const monday = moment(currentWeekStart);
    let offset = 0;
    const currentWeek = [];
    while (offset < 5) {
      currentWeek.push(moment(monday).add(offset++, "d").format("M/DD"));
    }

    return (
      <div id="schedule-container" style={{ position: "relative" }}>
        <ReactModal
          refreshFilters={this.refreshFilters}
          isShowing={this.state.modalOpen}
          page={this.state.currentModal}
          data={this.state.modalData}
          modalAction={this.setModal}
        />
        <div
          style={{
            transition: "all",
            flexWrap: "wrap",
            paddingRight: "13px",
            position: "absolute",
            top: "32px",
            right: "32px",
          }}
          className="flex-container flex-j-end"
        >
          {this.state.selectedProject.length === 1 && (
            <div
              style={{
                position: "absolute",
                top: "-10px",
                right: "177px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ paddingRight: "10px", whiteSpace: "nowrap" }}>
                {this.state.hoursBooked} hours booked
              </span>
              <DateRangePicker
                startDate={this.state.hoursWorkedStartDate}
                startDateId="hours_worked_start_date"
                endDate={this.state.hoursWorkedEndDate}
                endDateId="hours_worked_end_date"
                onDatesChange={this.handleHoursWorkedDateChange}
                focusedInput={this.state.hoursWorkedInputFocus}
                onFocusChange={(focusedInput) => {
                  this.setState({ hoursWorkedInputFocus: focusedInput });
                }}
                numberOfMonths={1}
                orientation="horizontal"
                daySize={30}
                navPrev={
                  <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                    <RoundDropdown rotate="rotate(90deg)" />
                  </div>
                }
                navNext={
                  <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                    <RoundDropdown rotate="rotate(-90deg)" />
                  </div>
                }
                hideKeyboardShortcutsPanel
                inputIconPosition="after"
                customInputIcon={<RoundDropdown width="22px" height="22px" />}
                isOutsideRange={this.isDayBlocked}
              />
            </div>
          )}

          {this.props.currentUser.role_id >= 4 && (
            <button
              onClick={this.addClosure}
              style={{
                display: "block",
                textAlign: "left",
                border: "none",
                background: "none",
                cursor: "pointer",
                color: "#519acc",
                zIndex: "100",
                transitionDuration: "0.5s, .666s, .2s, 0.5s",
                transitionDelay: showClear ? "0.3s" : "0s",
                fontFamily: "Arial",
                fontWeight: "400",
                fontSize: "13px",
                position: showClear ? "absolute" : "absolute",
                right: showClear ? "100px" : "13px",
                top: showClear ? "-1px" : "0px",
                paddingRight: showClear ? "10px" : "0px",
              }}
            >
              Closures
            </button>
          )}
          <button
            className="btn-flat"
            style={{
              zIndex: "100",
              pointerEvents: showClear ? "all" : "none",
              transitionProperty: "opacity, max-width, padding, transform",
              transitionDuration: "0.5s, .666s, .2s, 0.5s",
              transitionDelay: showClear ? "0.3s" : "0s",
              opacity: showClear ? "1" : "0",
              padding: "0",
              whiteSpace: "pre",
              fontFamily: "Arial",
              fontWeight: "400",
              fontSize: "13px",
            }}
            type="button"
            onClick={this.clearFilters}
          >
            {" "}
            Clear All Filters{" "}
          </button>
        </div>
        <PageNav
          currentUser={this.props.currentUser}
          pageIcon={<NewCalenderIcon />}
          pageName="Schedule"
          twoWeeks={this.state.twoWeeks}
          weekHandler={this.weekSwitchClick}
          searchKeyDownHandler={this.searchKeyDown}
          usedLetters={null}
          checkboxes={[
            {
              name: "selectedClient",
              title: "Client",
              handler: this.handleSelectChange,
              options: this.props.clients,
              selected: this.state.selectedClient,
              searchEnabled: true,
              right: "-70px",
            },
            {
              name: "selectedProject",
              title: "Project",
              handler: this.handleSelectChange,
              options: this.props.projects,
              searchEnabled: true,
              selected: this.state.selectedProject,
              right: "-70px",
            },
            {
              modalButton: {
                text: "Create a new filter",
                name: "add-filter",
                type: "user",
                currentUser: this.props.currentUser,
                handler: this.refreshFilters,
              },
              name: "selectedFilter",
              title: "User",
              right: "-70px",
              searchEnabled: true,
              handler: this.handleSelectChange,
              options:
                this.state.currentUserFilters.length >= 1
                  ? {
                      groups: [
                        {
                          name: "My Filters",
                          handler: this.handleSelectChange,
                          options: this.state.currentUserFilters,
                          classList: "user-option",
                        },
                        {
                          name: "Users",
                          handler: this.handleSelectChange,
                          options: this.props.users,
                          classList: "user-option",
                        },
                      ],
                    }
                  : this.props.users,
              selected: this.state.selectedFilter,
              filter: true,
              filterModalAction: this.editFilterClick,
            },
            {
              name: "selectedStatus",
              title: "Status",
              handler: this.handleSelectChange,
              width: "152px",
              options: [
                { id: 1, name: "Full-time" },
                { id: 2, name: "Part-time" },
                { id: 3, name: "Contractor" },
              ],
              selected: this.state.selectedStatus,
            },
            {
              modalButton: {
                text: "Create a new team",
                name: "add-filter",
                type: "team",
                currentUser: this.props.currentUser,
                handler: this.refreshFilters,
              },
              name: "selectedTeam",
              title: "Team",
              handler: this.handleSelectChange,
              options: this.state.teamFilters,
              selected: this.state.selectedTeam,
              filter: this.props.currentUser.role_id >= 3,
              optionFilter: this.props.currentUser.role_id < 3,
              filterModalAction: this.editFilterClick,
            },
          ]}
          scrollHandler={() => null}
          addHandler={null}
          addText=""
          clearFunction={this.clearFilters}
          filtersActive={
            this.state.selectedFilter.length +
              this.state.selectedProject.length +
              this.state.selectedClient.length +
              this.state.selectedStatus.length +
              this.state.selectedTeam.length >=
            1
          }
          search={this.state.search}
          tags={this.props.tags}
          updateSearchHandler={this.updateSearch}
        />
        <FiltersRow>
          <FiltersControls
            filtersLists={{
              client: {
                key: "selectedClient",
                label: "Client",
                list: this.props.clients,
                selectedIds: selectedClient,
              },
              project: {
                key: "selectedProject",
                label: "Project",
                list: this.props.projects,
                selectedIds: selectedProject,
              },
              user: {
                key: "selectedFilter",
                label: "User",
                list: this.props.currentUserFilters.concat(this.props.users),
                selectedIds: selectedFilter,
              },
              status: {
                key: "selectedStatus",
                label: "Status",
                list: [
                  { id: 1, name: "Full-time" },
                  { id: 2, name: "Part-time" },
                  { id: 3, name: "Contractor" },
                ],
                selectedIds: selectedStatus,
              },
              team: {
                key: "selectedTeam",
                label: "Team",
                list: this.props.teamFilters,
                selectedIds: selectedTeam,
              },
            }}
            clearFilter={this.clearSingleFilter}
          />
        </FiltersRow>
        <div
          className="date-nav-wrapper date-nav-wrapper-not-sticky"
          style={{ paddingRight: "10px" }}
        >
          <div className="date-nav">
            <div id="date-nav-header" className="date-nav-header">
              <Select
                classNamePrefix="react-select"
                options={[
                  { label: "Availability", value: true },
                  { label: "All Tasks", value: false },
                  { label: "Booked by Me", value: null },
                ]}
                onChange={this.onAvailabilityChange}
                value={this.state.availability}
                blurInputOnSelect
                theme={this.getSelectTheme}
                styles={{
                  ...SingleSelectStyles,
                  input: (styles) => ({ ...styles, color: "transparent" }),
                  option: (styles, props) => ({
                    ...styles,
                    backgroundColor: "white",
                    color: props.isSelected ? "#519acc" : "#313131",
                    cursor: "pointer",
                  }),
                  menu: (styles) => ({ ...styles, width: "180px" }),
                  valueContainer: (styles) => ({
                    ...styles,
                    height: "39px",
                    position: "relative",
                    top: "-4px",
                    display: "flex",
                  }),
                  container: (styles) => ({
                    ...styles,
                    width: "180px",
                    position: "absolute",
                    left: "10px",
                  }),
                  indicatorsContainer: (styles) => ({
                    ...styles,
                    position: "relative",
                    top: "-4px",
                    left: "-5px",
                  }),
                }}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {this.renderDateDisplay()}
              </div>
              {!todayIsInRange && (
                <a
                  id="todayBtn"
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    position: "absolute",
                    right: "220px",
                    top: "17px",
                  }}
                  onClick={this.resetWeek}
                >
                  <i
                    style={{ pointerEvents: "none" }}
                    className="material-icons white-text"
                  >
                    today
                  </i>
                </a>
              )}
              <CalendarContainer
                twoWeeks={this.state.twoWeeks}
                dateRange={
                  this.state.pickerDate
                    ? `${moment(this.state.pickerDate)
                        .day("monday")
                        .format("MM/DD")} - ${
                        this.state.twoWeeks
                          ? moment(this.state.pickerDate)
                              .day("friday")
                              .add(7, "days")
                              .format("MM/DD")
                          : moment(this.state.pickerDate)
                              .day("friday")
                              .format("MM/DD")
                      }`
                    : "Select a date"
                }
              >
                <div
                  id="start-date-container"
                  style={{ position: "absolute", right: "10px", top: "8px" }}
                  className="flex-j-start flex-container flex-al-center"
                >
                  <div
                    style={{
                      position: "relative",
                      left: "40px",
                      zIndex: 1,
                      top: "5px",
                    }}
                  >
                    <CalendarIcon width="22px" height="22px" color="#519acc" />
                  </div>
                  <SingleDatePicker
                    date={this.state.pickerDate}
                    onDateChange={this.onDateChangePicker}
                    focused={this.state.pickerFocus}
                    onFocusChange={this.onFocusChangePicker}
                    numberOfMonths={1}
                    placeholder="Select a date"
                    orientation="horizontal"
                    daySize={30}
                    navPrev={
                      <div className=".DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_leftButton__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault_5">
                        <RoundDropdown rotate="rotate(90deg)" />
                      </div>
                    }
                    navNext={
                      <div className="DayPickerNavigation_button DayPickerNavigation_button_1 DayPickerNavigation_button__default DayPickerNavigation_button__default_2 DayPickerNavigation_button__horizontal DayPickerNavigation_button__horizontal_3 DayPickerNavigation_button__horizontalDefault DayPickerNavigation_button__horizontalDefault_4 DayPickerNavigation_rightButton__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault_5">
                        <RoundDropdown rotate="rotate(-90deg)" />
                      </div>
                    }
                    hideKeyboardShortcutsPanel
                    inputIconPosition="after"
                    customInputIcon={
                      <RoundDropdown width="22px" height="22px" />
                    }
                    isOutsideRange={this.isDayBlocked}
                    id="single_date_picker"
                  />
                </div>
              </CalendarContainer>
            </div>
            <ScheduleDateHeaders
              filtersSelected={selectedProject.length >= 1}
              handleHideToggle={this.handleHideToggle}
              hideRow={this.state.hideRow}
              userCount={this.state.searchData.length}
              showDuplicateButton={selectedProject.length === 1}
              twoWeeks={this.state.twoWeeks}
              currentWeekStart={currentWeekStart}
            />
          </div>
        </div>
        <TasksTable
          search={this.state.search}
          searchData={this.state.searchData}
          users={this.state.users}
          selectedFilter={this.state.selectedFilter}
          selectedProject={this.state.selectedProject}
          selectedClient={this.state.selectedClient}
          selectedStatus={this.state.selectedStatus}
          selectedTeam={this.state.selectedTeam}
          availability={this.state.availability}
          currentWeekStart={this.state.currentWeekStart}
          closures={this.state.closures}
          setDraggingItem={this.setDraggingItem}
          getDraggingItem={this.getDraggingItem}
          setModal={this.setModal}
          dragDropAddTask={this.dragDropAddTask}
          allowedProjectIds={this.props.allowedProjectIds}
          currentUser={this.props.currentUser}
          twoWeeks={this.state.twoWeeks}
        />
        <div
          style={{ height: "0px", overflow: "hidden" }}
          id="drag-image-container"
        />
      </div>
    );
  }
}

const CalendarContainer = styled.div.attrs((props) => ({
  dateRange: props.dateRange,
  twoWeeks: props.twoWeeks,
}))`
  .CalendarMonth_table
    tr:hover
    .CalendarDay__default:not(.CalendarDay__blocked_out_of_range) {
    background-color: #519acc;
  }
  ${(props) =>
    props.twoWeeks
      ? `
    .CalendarMonth_table tr:hover + tr .CalendarDay__default:not(.CalendarDay__blocked_out_of_range){
      background-color: #519acc;
    }
    `
      : ""}

  .CalendarDay__default:hover {
    background-color: #519acc !important;
  }

  .CalendarDay__selected ~ td:not(.CalendarDay__blocked_out_of_range) {
    background: #519acc !important;
    color: #fff !important;
  }

  .CalendarDay__selected ~ td:not(.CalendarDay) {
    border-radius: 50%;
    opacity: 0.6;
  }

  .CalendarDay__selected ~ td:not(.CalendarDay):last-child {
    opacity: 0;
  }

  .selected-date-row + tr > td:not(.CalendarDay__blocked_out_of_range) {
    background: #519acc !important;
    color: #fff !important;
  }

  .selected-date-row + tr > td:not(.CalendarDay) {
    border-radius: 50%;
    opacity: 0.6;
  }

  .selected-date-row + tr > td:not(.CalendarDay):last-child {
    opacity: 0;
  }

  #start-date-container .DateInput::after {
    content: "${(props) => props.dateRange}";
    display: block;
    position: absolute;
    width: 100px;
    height: 22px;
    color: #484848;
    font-weight: 200;
    font-size: 14px;
    background-color: white;
    top: 2px;
    font-family: Arial;
  }
`;

const FiltersRow = styled.div`
  padding-right: 10px;
  width: 100%;
  margin-bottom: 24px;
`;

export default ScheduleContainer;
